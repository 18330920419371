export const Calender_icon = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 1000001292">
        {/* <rect id="Rectangle" width="38" height="38" rx="10" fill="#F1F3F6" /> */}
        <g id="vuesax/linear/calendar">
          <g id="calendar">
            <g id="Group 18">
              <path
                id="Vector"
                d="M15 9V12"
                stroke="#09110E"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_2"
                d="M23 9V12"
                stroke="#09110E"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_3"
                d="M10.5 16.0898H27.5"
                stroke="#09110E"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_4"
                d="M28 15.5V24C28 27 26.5 29 23 29H15C11.5 29 10 27 10 24V15.5C10 12.5 11.5 10.5 15 10.5H23C26.5 10.5 28 12.5 28 15.5Z"
                stroke="#09110E"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_5"
                d="M22.6937 20.6992H22.7027"
                stroke="#09110E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_6"
                d="M22.6937 23.6992H22.7027"
                stroke="#09110E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_7"
                d="M18.9945 20.6992H19.0035"
                stroke="#09110E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_8"
                d="M18.9945 23.6992H19.0035"
                stroke="#09110E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_9"
                d="M15.2953 20.6992H15.3043"
                stroke="#09110E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_10"
                d="M15.2953 23.6992H15.3043"
                stroke="#09110E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const UnCheckedIcon = () => {
  return (
    <div
      style={{
        height: "17px",
        width: "17px",
        borderRadius: "3px",
        border: "1px solid #223430",
      }}
    />
  );
};

export const CheckedIcon = () => {
  return (
    <div
      style={{
        height: "17px",
        width: "17px",
        borderRadius: "3px",
        border: "1px solid #F48C06",
        backgroundColor: "#F48C06",
      }}
    >
      <svg
        width="21px"
        height="19px"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.85938 9L7.49574 13L13.8594 6"
          stroke="white"
          stroke-width="2"
        />
      </svg>
    </div>
  );
};
