import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    OutlinedInput,
    FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiServices from "../../config/ApiServices";
import ApiEndpoint from "../../config/ApiEndpoint";
import { Types } from "../../constants/actionTypes";
import { connect } from "react-redux";
import Constants from "../../config/Constants";
import Loader from "../Component/loader";
import styles from "./advertisement.module.scss";

const Add_Advertisement = (props) => {
    const [imageId, setImageId] = useState(null);
    const [sportsList, setSportsList] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        SportsList();
        RolesList();
    }, []);

    const RolesList = async () => {
        try {
            const headers ={
                "Content-Type": "application/json",
                "x-access-token": props.profile.token
            }
            var response = await ApiServices.GetApiCall(
                `${ApiEndpoint.ROLES_LIST}`,
                headers
              );
           
            if (response.status == true) {            
                setRolesList(response.data)              
               } else {
                toast.error('Failed to fetch roles.');
            }
        } catch (error) {
            console.error('Error fetching roles:', error);
            toast.error('Failed to fetch roles.');
        }
    };

    const SportsList = async () => {
        var headers = {
            "Content-Type": "application/json",
            "x-access-token": props.profile.token,
        };
        const sportsData = await ApiServices.PostApiCall(
            `${ApiEndpoint.SPORT_LIST_COMMON}`, {},
            headers
        );
        if (sportsData && sportsData.status && sportsData.data) {
            const sportNames = sportsData.data.map(sport => ({
                id: sport.id,
                name: sport.name
            }));
            setSportsList(sportNames);
        }
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === "image/jpeg" || file.type === "image/png") {
                    setSelectedFile(file);
                    const uploadResponse = await uploadItem(event);
                    console.log("uploadResponse");
                    if (uploadResponse && uploadResponse.status) {
                        setImageId(uploadResponse.data.id);
                    } else {
                        toast.error('Failed to upload image.');
                    }
              
            } else {
                alert("Only JPG and PNG files are allowed.");
            }
        }
    };

    const uploadItem = async (e) => {
        var filename = e.target.files[0];
        var formData = new FormData();
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", props.profile.token);
        formData.append("file", filename, filename.name);
        formData.append("type", "image");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
        };

        setLoading(true);
        const data = await fetch(
            `${Constants.BASE_API_URL}user/upload`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                return result;
            })
            .catch((error) => console.error("error", error));

        return data;
    };


    const validationSchema = Yup.object({
        title: Yup.string().max(100, "Title must be at most 100 characters").required("Title is required"),
        websiteLink: Yup.string().url("Enter a valid URL").required("Website Link is required"),
        selectedSport: Yup.string().required("Select a sport"),
        selectedRole: Yup.string().required("Select a role"),
    });

    const formik = useFormik({
        initialValues: {
            title: "",
            websiteLink: "",
            selectedSport: "",
            selectedRole: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsSubmitting(true); 
            try {
                if (!imageId) {
                    toast.error('Please upload an image before submitting the form.');
                    setIsSubmitting(false);
                    return;
                }
                const body = {
                    name: values.title,
                    sport_id: values.selectedSport,
                    url_link: values.websiteLink,
                    id_item_logo: imageId.toString(),
                    user_type: values.selectedRole,
                };
               const headers ={
                    "Content-Type": "application/json",
                    "x-access-token": props.profile.token
                }
                const response = await ApiServices.PostApiCall(
                    ApiEndpoint.ADD_ADVERTISEMENT,
                    JSON.stringify(body),
                    headers
                  );
                if (response.status == true) {
                    toast.success(response.message, {
                        onClose: () => navigate("/advertisement"),
                        autoClose: 3000,
                        
                    });
                } else {
                  toast.error(response.message);
                }
            } catch (error) {
                console.log(error.message);
                toast.error("Error submitting form");
            }
            //  finally {
            //     setIsSubmitting(false); // Re-enable the button after the response
            // }
        },
    });

 
    return (
        <Box className={styles.contenar_grid_mddd}>
        <Box className={styles.box_main_dashboard_two}>
     
              {loading && <Loader />}
            <Typography className={styles.Dashboard_text_typo}>
                Add Advertisement
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} className={styles.formWrapper }>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            error={formik.touched.selectedSport && Boolean(formik.errors.selectedSport)}
                        >
                            <InputLabel id="select-sport-label">Select Sport</InputLabel>
                            <Select
                                labelId="select-sport-label"
                                id="selectedSport"
                                name="selectedSport"
                                value={formik.values.selectedSport}
                                onChange={formik.handleChange}
                                input={<OutlinedInput label="Select Sport" />}
                            >
                                {sportsList.map((sport) => (
                                    <MenuItem key={sport.id} value={sport.id}>
                                        {sport.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.selectedSport && formik.errors.selectedSport && (
                                <FormHelperText>{formik.errors.selectedSport}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            error={formik.touched.selectedRole && Boolean(formik.errors.selectedRole)}
                        >
                            <InputLabel id="select-role-label">Select Role</InputLabel>
                            <Select
                                labelId="select-role-label"
                                id="selectedRole"
                                name="selectedRole"
                                value={formik.values.selectedRole}
                                onChange={formik.handleChange}
                                input={<OutlinedInput label="Select Role" />}
                            >
                                {rolesList.map((role) => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.selectedRole && formik.errors.selectedRole && (
                                <FormHelperText>{formik.errors.selectedRole}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Title"
                            variant="outlined"
                            fullWidth
                            id="title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.title}
                            helperText={formik.errors.title}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Website Link"
                            variant="outlined"
                            fullWidth
                            id="websiteLink"
                            name="websiteLink"
                            value={formik.values.websiteLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.websiteLink && Boolean(formik.errors.websiteLink)}
                            helperText={formik.touched.websiteLink && formik.errors.websiteLink}
                        />
                    </Grid>
                   
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            component="label"
                        >
                            Upload Image
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {imageId && (
                            <Box>
                                 <img 
                                    src={URL.createObjectURL(selectedFile)} 
                                    alt="Uploaded" 
                                    style={{ width: 100, height: 100, objectFit: 'cover', marginTop: '10px' }} 
                                />
                            </Box>
                        )}
                    </Grid>
                 
                </Grid>
                <Grid item xs={12} textAlign="right">
                        <Button className={styles.save_btn } variant="contained" color="primary" type="submit"   disabled={isSubmitting || loading}>
                        {isSubmitting ? 'Submitting...' : 'Save'}
                        </Button>
                    </Grid>
            </form>
      
        </Box>
        </Box>

    );
};

const mapStateToProps = (state) => ({
    profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
    save_user_data: (data) => dispatch({ type: Types.LOGIN, payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Add_Advertisement);
