import { Box, TextField, Typography, styled } from "@mui/material";
import React from "react";

export const InputBox = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "0px",
  width: "100%",
}));

export const Input_field = styled(TextField)(({ color }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    padding: "10px",
  },
}));

export const Input_lable = styled(Typography)(({}) => ({
  fontSize: "15px",
}));
