import Grid from '@mui/material/Grid';
import styles from './login.module.scss';
import { Box, Typography, TextField, Button } from '@mui/material'
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import ApiServices from '../../config/ApiServices';
import ApiEndpoint from '../../config/ApiEndpoint'
import { toast } from "react-toastify";
import { Types } from "../../constants/actionTypes";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField_Err from '../inputerr/textfild_err';
 import Errmass from '../tost/tostmass';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ForgotPassword = (props) => {
    const [objpash, setObjectpass] = React.useState({})
    const [suuturerename, setSusuufullrename] = React.useState(false)

    React.useEffect(() => {
        let datalog = null;
        if (suuturerename == true) {
          datalog = {
            masss: `Instructions sent to ${formik.values.email}`,
            turelog: true,
          }
          setTimeout(() => setSusuufullrename(false), 3000)
        } else {
          datalog = {
            masss: "File “PPO Mac 3 plan” was renamed to “PPO Mac 222”. ",
            turelog: false,
          }
        }
        setObjectpass(datalog)
      }, [suuturerename])

    const onForgotPress = async () => {
        var body = {
            email: formik.values.email,
        };
        var headers = {
            "Content-Type": "application/json",
        };
        var data = await ApiServices.PostApiCall(
            ApiEndpoint.FORGOT_PASSWORD,
            JSON.stringify(body),
            headers
        );
        // props.props.loaderRef(false);
        if (!!data) {
            if (data.status == true ) {
                setSusuufullrename(true)
            }else{
                toast.error(data.message);
            }
        }
    };
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required("Email is required")
                .email("Email is invalid"),
        }),
        onSubmit: () => {
            onForgotPress();
        },
    });
    return (
        <>
            <Grid container className={styles.contenar_login}>
                <Grid item md={6} className={styles.grid_item_jjjss}>
                    <Box className={styles.box_imgsffsf}>
                        <img src='/Rectangle 4915.jpg' width={'100%'} height={621} />
                    </Box>
                </Grid>
                <Grid item md={6} className={styles.grid_two_djjdhdhhd}>
                    <Box className={styles.vbox_img_jjjaaaa}>
                        <img width={70} src='/XMLID_2187_.svg' />
                    </Box>
                    <Box className={styles.box_typo_adminn}>
                        <Typography className={styles.typo_hidungafafa}>
                        Forgot Password
                        </Typography>
                        <Typography className={styles.well_come_typo}>
                        Enter your email address and we’ll send you password reset instructions.
                        </Typography>
                        <Divider className={styles.diveydarrafafa} />
                        <form onSubmit={formik.handleSubmit}>
                            <TextField
                                name="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                className={styles.text_fieldd_login}
                                id="filled-password-input"
                                label="Email Address*"
                                type="text"
                                autoComplete="current-password"
                                variant="filled"
                            />
                            <TextField_Err errors={formik.errors.email} touched={formik.touched.email} />
                            <Box className={styles._main_box_sjjssss}>
                                <Box className={styles.box_check_box_sssss}>
                                    <Checkbox className={styles.chec_box_shhshhs} icon={<img src='/Rectangle 4813.svg' />} {...label} defaultChecked />
                                    <Typography>Remember me</Typography>
                                </Box>
                                <Box className={styles.box_check_box_sssss_two}>
                                    <Button>Forgot Password?</Button>
                                </Box>

                            </Box>
                            <Box className={styles._bottom_btn_loginnna}>
                                <Button type='submit'>
                                Send Email
                                </Button>
                             
                            </Box>
                            <Errmass mass={objpash} />
                            <Box className={styles.box_test_centar}>
                            <Button onClick={()=>{window.location.href = '/'}} className={styles.btn_back_loginn}>
                                Back to Login
                                </Button >
                                </Box>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </>
    )

}
const mapStateToProps = (state) => ({
    profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
    save_user_data: (data) => dispatch({ type: Types.LOGIN, payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
