import {
  Box,
  Button,
  Grid,
  Typography,
  createTheme,
  Menu,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
} from "@mui/material";
import styles from "./session.module.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import Pagination from '@mui/material/Pagination';
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import ApiServices from "../../config/ApiServices";
import ApiEndpoint from "../../config/ApiEndpoint";
import { useState } from "react";
import { InputBox, Input_field, Input_lable } from "../Shareable/input";
import { toast } from "react-toastify";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getTimeDifferenceMessage } from "../../Utils/func";
import { useNavigate } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const Session = (props) => {
  const PAGE_LIMIT = 10;
  const [open, setOpen] = React.useState(false);
  const [datalist, setDataLIST] = useState([]);
  const [open_two, setOpen_two] = React.useState(false);
  const [data_porfele, setData_porfile] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterName, setFilterName] = React.useState("");
  const [premiumUser, setPremiumUser] = React.useState("");
  const [accountType, setAccountType] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState("");
  const [totalUsers, setTotalUsers] = React.useState("");
  const [startNumber, setStartNumber] = React.useState(1);
  const [endNumber, setEndNumber] = React.useState(PAGE_LIMIT);
  const [userSearch, setUserSearch] = React.useState([]);
  let navigate = useNavigate();

  const [apiQuery, setApiQuery] = React.useState({
    premiumUser: "",
    gender: "",
    accountType: "",
    name: "",
  });

  const array = [...Array(totalPages).keys()];
 


  // const userLIST = async () => {
  //   var body = {
  //     type: 1,
  //     pagination: true,
  //     page: selectedPage,
  //     limit: PAGE_LIMIT
  //   };

  //   var headers = {
  //     "Content-Type": "application/json",
  //     "x-access-token": props.props.profile.token,
  //   };

  //   props.setIsLoaded(true);

  //   var endpoint = "http://localhost:2020/api/admin/user/alllist";

  //   var data = await ApiServices.PostApiCall(endpoint, JSON.stringify(body), headers);

  //   props.setIsLoaded(false);
  //   console.log("data: ", data.data[0].umphireApplyList);
  //   if (!!data) {
  //     if (data.status == true) {
  //       setDataLIST(data.data);
  //       setTotalPages(data.totalPage);
  //       setUserSearch(data.data);
  //       setTotalUsers(data.count);
  //       const Arr = [];
  //     } else {
  //       toast.error(data.message);
  //     }
  //   }
  // };
  const userLIST = async () => {
    var body = {
      type: 1,
      pagination: true,
      page: selectedPage,
      limit: PAGE_LIMIT
    };
  
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.props.profile.token,
    };
  
    props.setIsLoaded(true);
  
    var endpoint = ApiEndpoint.SESSION_LIST;
  
    try {
      const response = await ApiServices.PostApiCall(endpoint, JSON.stringify(body), headers);
      if (response.status === true) {
        setDataLIST(response.data); // Replace the existing data with the new data for the current page
        setTotalPages(response.totalPage);
        setUserSearch(response.data);
        setTotalUsers(response.count);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    } finally {
      props.setIsLoaded(false);
    }
  };
  

  console.log("Datalist: ", datalist);
  // console.log("data.data: ",data.data);



  const umphireDelete = async (id) => {
    var body = {
      id_user: id,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.props.profile.token,
    };
    props.setIsLoaded(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.UMPHIRE_DELETE,
      JSON.stringify(body),
      headers
    );

    props.setIsLoaded(false);
    if (!!data) {
      if (data.status == true) {
        userLIST();
      } else {
        // toast.error(data.message)
      }
    }
  };

  React.useEffect(() => {
    if (!!props.props.profile && !!props.props.profile.token) {
      userLIST();
    }
  }, [
    props.props.profile,
    selectedPage,
    premiumUser,
    accountType,
    gender,
    filterName,
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen_two = () => {
    setOpen_two(true);
  };

  const handleClose_two = () => {
    setOpen_two(false);
  };
  const themeline = createTheme({ palette: { primary: { main: "#FF7606" } } });



  console.log("datalist373  ",datalist);
  console.log("row?.fixtureOrganisation[0]?.userOrganisation[0]?.school_type  ",datalist[0]?.fixtureOrganisation?.userOrganisation[0]?.school_type);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <Box className={styles.box_class_in_fast_popup}>
          <Box className={styles.typo_text_hedingg}>
            <Box></Box>
            <Typography>User Profile</Typography>
            <Button onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Box>
          <Box
            className={styles.box_img_an_ditelas}
            style={{ paddingTop: "25px" }}
          >
            <img width={99} height={99} src={data_porfele.profile_image} />
            <Typography className={styles.typo_name_detelas}>
              {data_porfele.first_name} {data_porfele.last_name}
            </Typography>
            <Typography className={styles.not_tasker_typooo}>
              Not a Tasker
            </Typography>
          </Box>
          <Box className={styles.box_in_info_email}>
            <Typography className={styles.info_text_personn}>
              Personal Info
            </Typography>
            <Box className={styles.info_box_pohoneee}>
              <Box className={styles.box_email_last}>
                <Typography className={styles.heding_emakakaaa}>
                  Email Address
                </Typography>
                <Typography className={styles.deelassss_Ssss}>
                  {data_porfele.email}
                </Typography>
              </Box>
              <Box className={styles.box_email_last}>
                <Typography className={styles.heding_emakakaaa}>
                  Phone Number
                </Typography>
                <Typography className={styles.deelassss_Ssss}>
                  {data_porfele.mobile}
                </Typography>
              </Box>
              <Box className={styles.box_email_last}>
                <Typography className={styles.heding_emakakaaa}>
                  Gender
                </Typography>
                <Typography className={styles.deelassss_Ssss}>
                  {data_porfele.gender}
                </Typography>
              </Box>
              <Box className={styles.box_email_last}>
                <Typography className={styles.heding_emakakaaa}>Age</Typography>
                <Typography className={styles.deelassss_Ssss}>
                  {data_porfele.age}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.box_gallery_imgg}>
            <Typography>Gallery</Typography>
            <Box className={styles.box_gallery_img_agess}>
              {data_porfele.length < 0 &&
                data_porfele.galleryList.map((row) => (
                  <img width={105} height={105} src={row.url} />
                ))}
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open_two}
        onClose={handleClose_two}
      >
        <Box className={styles.box_class_in_fast_popup}>
          <Box className={styles.typo_text_hedingg}>
            <Box></Box>
            <Typography>User Profile</Typography>
            <Button onClick={handleClose_two}>
              <CloseIcon />
            </Button>
          </Box>
          <Box className={styles.box_img_an_ditelas}>
            <img
              width={99}
              height={99}
              src={
                data_porfele.role == "schools"
                  ? data_porfele?.organisationDetails?.logo_url
                  : data_porfele.profile_image
              }
            />
            <Typography className={styles.typo_name_detelas}>
              {data_porfele.role != "schools"
                ? data_porfele.first_name + " " + data_porfele.last_name
                : data_porfele?.organisationDetails?.name}
            </Typography>
            <Typography className={styles.not_tasker_typooo}>
              Joined {data_porfele.role}{" "}
              {getTimeDifferenceMessage(new Date(data_porfele.createdAt))}
            </Typography>
          </Box>
          <Box className={styles.box_in_info_email}>
            <Typography className={styles.info_text_personn}>
              Personal Info
            </Typography>
            <Box className={styles.info_box_pohoneee}>
              <Box className={styles.box_email_last}>
                <Typography className={styles.heding_emakakaaa}>
                  Email Address
                </Typography>
                <Typography className={styles.deelassss_Ssss}>
                  {data_porfele.email}
                </Typography>
              </Box>
              {data_porfele.phone_number ? (
                <Box className={styles.box_email_last}>
                  <Typography className={styles.heding_emakakaaa}>
                    Phone Number
                  </Typography>
                  <Typography className={styles.deelassss_Ssss}>
                    {data_porfele.phone_number}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              {data_porfele.sport ? (
                <Box className={styles.box_email_last}>
                  <Typography className={styles.heding_emakakaaa}>
                    Sport
                  </Typography>
                  <Typography className={styles.deelassss_Ssss}>
                    {data_porfele.sport}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              {data_porfele.userSchoolAddress ? (
                <>
                  <Box className={styles.box_email_last}>
                    <Typography className={styles.heding_emakakaaa}>
                      Post code
                    </Typography>
                    <Typography className={styles.deelassss_Ssss}>
                      {data_porfele?.userSchoolAddress?.post_code}
                    </Typography>
                  </Box>
                  <Box className={styles.box_email_last}>
                    <Typography className={styles.heding_emakakaaa}>
                      Street
                    </Typography>
                    <Typography className={styles.deelassss_Ssss}>
                      {data_porfele?.userSchoolAddress?.street_name}
                    </Typography>
                  </Box>
                  <Box className={styles.box_email_last}>
                    <Typography className={styles.heding_emakakaaa}>
                      Suburb
                    </Typography>
                    <Typography className={styles.deelassss_Ssss}>
                      {data_porfele?.userSchoolAddress?.suburb}
                    </Typography>
                  </Box>
                </>
              ) : (
                ""
              )}

              {data_porfele.rating ? (
                <Box className={styles.box_email_last}>
                  <Typography className={styles.heding_emakakaaa}>
                    Rating
                  </Typography>
                  <Typography className={styles.deelassss_Ssss}>
                    {data_porfele.rating}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              {data_porfele.refereed_fixture ? (
                <Box className={styles.box_email_last}>
                  <Typography className={styles.heding_emakakaaa}>
                    Refereed Sessions{" "}
                  </Typography>
                  <Typography className={styles.deelassss_Ssss}>
                    {data_porfele.refereed_fixture}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
          {data_porfele?.bio?.length > 0 ? (
            <Box style={{ marginTop: "10px" }}>
              <Typography>Bio</Typography>

              <Typography style={{ fontSize: "12px" }}>
                {data_porfele?.bio}
              </Typography>
            </Box>
          ) : (
            ""
          )}

          {data_porfele?.recommendedList?.length > 0 ? (
            <Box className={styles.box_text_tasks_imggg}>
              <Typography>RECOMMENDED BY</Typography>
              <Box>
                {data_porfele?.recommendedList?.length > 0
                  ? data_porfele.recommendedList.map((item, index) => {
                    return (
                      <>
                        <Box style={{ display: "flex", gap: "15px" }}>
                          <Box className={styles.tag_box}>
                            <div className="w-10 self-stretch py-0.5 justify-center items-center gap-2.5 flex">
                              <img
                                className="w-10 h-10 relative rounded-[128px]"
                                src={item.logo_url}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </Box>

                          <div className="grow shrink basis-0 h-14 py-2 justify-start items-center gap-3 flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                              <div className="self-stretch h-[22px] text-neutral-950 text-[17px] font-normal font-['Roboto'] leading-snug">
                                {item.name}
                              </div>
                              <div className="self-stretch text-zinc-700 text-[13px] font-normal font-['Roboto'] leading-[18px]">
                                {item.school_type}
                              </div>
                            </div>
                            <div className="w-10 self-stretch" />
                          </div>
                        </Box>
                      </>
                    );
                  })
                  : ""}
              </Box>
            </Box>
          ) : (
            ""
          )}
          {data_porfele?.documentList?.length > 0 ? (
            <Box className={styles.box_main_aupoad_fileee}>
              <Typography>Certifications</Typography>
              <Box className={styles.img_inuplode_filee_img}>
                {!!data_porfele.documentList &&
                  data_porfele.documentList.length !== 0 &&
                  data_porfele.documentList.map((row) => (
                    <a href={row.document_url} target="_blank">
                      {row.type}
                    </a>
                  ))}
              </Box>
            </Box>
          ) : (
            ""
          )}
          {data_porfele?.sportList?.length > 0 ? (
            <Box className={styles.box_main_video_this}>
              <Typography>Sports</Typography>
              <Box
                style={{ display: "flex", gap: "10px" }}
                className={styles.video_tage_box}
              >
                {!!data_porfele.sportList &&
                  data_porfele.sportList.length !== 0 &&
                  data_porfele.sportList.map((row) => (
                    <>
                      <Typography>{row.name}, </Typography>
                    </>
                  ))}
              </Box>
            </Box>
          ) : (
            ""
          )}

          {data_porfele?.userSchoolSport?.length > 0 ? (
            <Box className={styles.box_main_video_this}>
              <Typography>Sports</Typography>
              <Box
                style={{ display: "flex", gap: "10px" }}
                className={styles.video_tage_box}
              >
                {!!data_porfele.userSchoolSport &&
                  data_porfele.userSchoolSport.length !== 0 &&
                  data_porfele.userSchoolSport.map((row) => (
                    <>
                      <Typography>{row.name}, </Typography>
                    </>
                  ))}
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Dialog>

      <Dialog
        open={anchorEl}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          setAnchorEl(false);
        }}
      >
        <Box className={styles.main_box_dialog}>
          <Box className={styles.insideBox}>
            <Grid
              container
              sx={{
                "& .MuiGrid-item": {
                  padding: "5px",
                },
              }}
            >
              <Grid item md={12}>
                <InputBox>
                  <Input_lable>Account Type</Input_lable>

                  <RadioGroup
                    value={apiQuery.accountType}
                    onChange={(event) => {
                      const apiQueryObj = { ...apiQuery };

                      apiQueryObj.accountType = event.target.value;
                      setApiQuery(apiQueryObj);
                    }}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      "& .MuiFormControlLabel-root": {
                        padding: "0px",
                      },
                    }}
                  >
                    <FormControlLabel
                      value="umphires"
                      control={<Radio />}
                      label="Umpire"
                    />
                    <FormControlLabel
                      value="verifier"
                      control={<Radio />}
                      label="Verifier"
                    />
                    <FormControlLabel
                      value="schools"
                      control={<Radio />}
                      label="Organisations"
                    />
                  </RadioGroup>
                </InputBox>
              </Grid>
              {/* <Grid item md={6}>
                <InputBox>
                  <Input_lable>Gender</Input_lable>

                  <RadioGroup
                    name="radio-buttons-group"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      "& .MuiFormControlLabel-root": {
                        padding: "0px",
                      },
                    }}
                    value={apiQuery.gender}
                    onChange={(event) => {
                      const apiQueryObj = { ...apiQuery };
                      apiQueryObj.gender = event.target.value;
                      setApiQuery(apiQueryObj);
                    }}
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                </InputBox>
              </Grid> */}
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  columnGap: "10px",
                }}
              >
                <Button
                  className={styles.cancle_button}
                  onClick={() => {
                    setAnchorEl(false);
                    const defaultobj = {
                      premiumUser: "",
                      gender: "",
                      accountType: "",
                    };

                    setApiQuery(defaultobj);
                    setAccountType("");
                    setPremiumUser("");
                    setGender("");
                    setFilterName("");
                  }}
                >
                  Clear
                </Button>
                <Button
                  className={styles.cancle_button}
                  onClick={() => {
                    setAnchorEl(false);
                    setAccountType(apiQuery.accountType);
                    setPremiumUser(apiQuery.premiumUser);
                    setFilterName(apiQuery.name);
                    setGender(apiQuery.gender);
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>

      <Box className={styles.contenar_grid_mddd}>
        <Box className={styles.box_main_dashboard_two}>
          <Box>
            <Typography className={styles.Dashboard_text_typo}>
              Sessions
            </Typography>
            <Typography className={styles.typo_sub_data_two}>
              You can see the overall Sessions here
            </Typography>
          </Box>
          <Box className={styles.box_btn_input}>
            {/* <Button
              className={styles.filter_img_btn_fill}
              onClick={() => {
                setAnchorEl(true);
              }}
            >
              <img src="/Filter.svg" />
              Filters
            </Button> */}
            <input
              type="text"
              id="myserchbtn"
              name="search"
              placeholder="Search"
              className={styles.searchbtn}
              autoComplete="off"
              onChange={(event) => {
                var value_ = event.target.value;
                console.log("value: ",value_ );
                if (typeof value_ !== "object") {
                  if (!value_ || value_ == "") {
                    console.log("userSearch: ",userSearch);
                    setDataLIST(userSearch);
                  } else {
                    var filteredData = userSearch.filter((item) => {
                      console.log("item: ",item?.name);
                      let searchValue = (
                        item?.name 
                      ).toLowerCase();
                      return searchValue.includes(
                        value_.toString().toLowerCase()
                      );
                    });
                    setDataLIST(filteredData);
                  }
                }
              }}
            />
          </Box>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              className={styles.tebal_dashbords}
              aria-label="simple table"
            >

              <TableHead>
                <TableRow >
                  <TableCell tyle={{ width: '15%' }} align="left">Title</TableCell>
                  <TableCell tyle={{ width: '15%' }} align="left">Sport Type</TableCell>
                  <TableCell tyle={{ width: '15%' }} align="left">Type</TableCell>
                  <TableCell tyle={{ width: '15%' }} align="left">Date</TableCell>
                  <TableCell tyle={{ width: '15%' }} align="left">Time</TableCell>
                  <TableCell tyle={{ width: '15%' }} align="left">Budget $</TableCell>
                  <TableCell tyle={{ width: '15%' }} align="left">Organisation</TableCell>
                  <TableCell tyle={{ width: '15%' }} align="left">Name</TableCell>
                  <TableCell style={{ width: '15%' }} align="left">Address</TableCell>
                  <TableCell tyle={{ width: '15%' }} align="left">Payment Status</TableCell>
                  {/* <TableCell style={{ width: '15%' }} align="left">Applicants</TableCell> */}
                </TableRow>
              </TableHead>


              <TableBody>
                {datalist.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      scope="row"
                    //   onClick={() => {
                    //     navigate(
                    //       "/userdetails?id=" + row.id_user + "&role=" + row?.fixtureOrganisation?.role
                    //     );
                    // }}
                    >
                      <Box className={styles.box_img_ros_sss}>
                        {row.profile_image ? (
                          <img
                            src={row.profile_image}
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <Typography>
                          {row.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell tyle={{ width: '15%' }} align="left">{row?.fixtureSport?.name}</TableCell>
                    <TableCell tyle={{ width: '15%' }} align="left">{row?.fixtureUmphire[0]?.umphire_type}</TableCell>
                    <TableCell tyle={{ width: '15%' }} align="left">{row?.date}</TableCell>
                    <TableCell tyle={{ width: '15%' }} align="left">{row?.time}</TableCell>
                    <TableCell tyle={{ width: '15%' }} align="left">{row?.fixtureUmphire[0]?.budget}</TableCell>
                    <TableCell tyle={{ width: '15%' }} align="left">{row?.fixtureOrganisation?.userOrganisation[0]?.school_type}</TableCell>
                    <TableCell tyle={{ width: '15%' }} align="left">{row?.fixtureOrganisation?.userOrganisation[0]?.name}</TableCell>
                    <TableCell style={{ width: '15%' }} align="left">{row?.address ? row.address : '-'}</TableCell>
                    {/* <TableCell style={{ width: '15%' }} align="left"> {row?.status === 'complete' ? row?.fixtureOrganisation?.role === 'schools'? <img title="Job completed but payment outside the system" src="/tick-circle-1.png" alt="Completed"/> : <img title='Payment Completed' src="/tick-circle.png" alt="Completed"/> : <img title="Payment Pending" src="/close-circle.png" alt="Not Complete"/>}</TableCell> */}
                    <TableCell tyle={{ width: '15%' }} align="left"> {row?.status === 'complete' ? <img title='Payment Completed' src="/tick-circle.png" alt="Completed"/> : <img title="Payment Pending" src="/close-circle.png" alt="Not Complete"/>}</TableCell>
                    {/* <TableCell style={{ width: '15%' }} align="left">{row?.umphireApplyList[0]?.length ? row?.umphireApplyList[0]?.length : 0}</TableCell> */}

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className={styles.this_box_classs_noo}>
          <Typography>
            Showing {startNumber} to {endNumber} of{" "}
            {totalUsers > 0 ? totalUsers : 0} new entries
          </Typography>
          <Box className={styles.box_typo_paginehsann}>
            {array.map((item, index) => {
              return (
                <>
                  <Button
                    className={styles.number_button}
                    sx={{
                      backgroundColor:
                        selectedPage == item + 1 ? "#088164 !important" : "",
                      color: selectedPage == item + 1 ? "#fff !important" : "",
                    }}
                    onClick={() => {
                      const totalNumber = totalUsers;
                      const pageSize = PAGE_LIMIT;
                      const numberOfParts = Math.ceil(totalNumber / pageSize);
                      const ranges = [...Array(numberOfParts).keys()].map(
                        (index) => {
                          const start = index * pageSize + 1;
                          const end = Math.min(
                            (index + 1) * pageSize,
                            totalNumber
                          );
                          const obj = {
                            start: start,
                            end: end,
                          };
                          return obj;
                        }
                      );

                      setStartNumber(ranges[item].start);
                      setEndNumber(ranges[item].end);
                      setSelectedPage(item + 1);
                    }}
                  >
                    {item + 1}
                  </Button>
                </>
              );
            })}
            <IconButton
              disabled={selectedPage == 1 ? true : false}
              onClick={() => {
                if (selectedPage > 0) {
                  setSelectedPage(selectedPage - 1);
                  const totalCount = totalUsers;
                  const groupSize = PAGE_LIMIT;
                  setStartNumber(Math.max(startNumber - PAGE_LIMIT, 1));
                  const lastGroupSize = totalCount % groupSize;
                  if (endNumber == totalCount) {
                    setEndNumber(endNumber - lastGroupSize);
                  } else {
                    setEndNumber(
                      Math.max(
                        endNumber - PAGE_LIMIT,
                        PAGE_LIMIT > totalUsers ? totalUsers : PAGE_LIMIT
                      )
                    );
                  }
                }
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton
              disabled={totalPages == selectedPage ? true : false}
              onClick={() => {
                if (totalPages >= selectedPage) {
                  setSelectedPage(selectedPage + 1);
                  const totalCount = totalUsers;
                  const groupSize = PAGE_LIMIT;
                  const lastGroupSize = totalCount % groupSize;
                  setStartNumber(
                    Math.min(
                      startNumber + PAGE_LIMIT,
                      totalUsers - lastGroupSize + 1
                    )
                  );
                  setEndNumber(Math.min(endNumber + PAGE_LIMIT, totalUsers));
                }
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Session;
