
import * as React from 'react';

import { Types } from "../../constants/actionTypes";
import { connect } from "react-redux";
const Headar = () => {
    return (
      <></>
    )
}
const mapStateToProps = (state) => ({
    profile: state.user.profile
  });
  
  const mapDispatchToProps = (dispatch) => ({
    save_user_data: (data) =>
        dispatch({ type: Types.LOGIN, payload: data }),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Headar);
