import { Grid } from "@mui/material";
import Sidebar from "../sidebar/sidebar";
import Headar from "../headar/headar";
import Guidelines from "../Guidelines/Guidelines";
const Dashboard_layout = ({ props, setIsLoaded }) => {
  return (
    <>
      <Grid container>
        <Grid item md={2.3}>
          <Sidebar />
        </Grid>
        <Grid item md={9.7}>
          <Headar />
          <Guidelines props={props} setIsLoaded={setIsLoaded} />
        </Grid>
      </Grid>
    </>
  );
};
export default Dashboard_layout;
