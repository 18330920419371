import {
  Box,
  Button,
  Grid,
  Typography,
  createTheme,
  IconButton,
} from "@mui/material";
import styles from "./Dashboard.module.scss";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MoreVertIcon from "@mui/icons-material/MoreVert";
// import Pagination from '@mui/material/Pagination';
import ApiServices from "../../config/ApiServices";
import ApiEndpoint from "../../config/ApiEndpoint";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import React from "react";
import { useState } from "react";

const Dashboard = (props) => {
  const [datalist, setDataLIST] = useState([]);

  const [dataLIST_user, setData_listuser] = useState("");
  const PAGE_LIMIT = 10;
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState("");
  const [totalUsers, setTotalUsers] = React.useState("");
  const [startNumber, setStartNumber] = React.useState(1);
  const [endNumber, setEndNumber] = React.useState(PAGE_LIMIT);
  const array = [...Array(totalPages).keys()];

  const themeline = createTheme({ palette: { primary: { main: "#FF7606" } } });
  // USER_TOTEL_DATA ADMIN_USER_LIST
  const accounttype = async () => {
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.props.profile.token,
    };
    props.setIsLoaded(true);
    var data = await ApiServices.GetApiCall(
      ApiEndpoint.USER_TOTEL_DATA,
      headers
    );

    props.setIsLoaded(false);

    if (!!data) {
      if (data.status == true) {
        setData_listuser(data.data);
      }
    }
  };
  const userLIST = async () => {
    var body = {
      type: "signup",
      page: selectedPage,
      limit: PAGE_LIMIT,
      pagination: true,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.props.profile.token,
    };
    props.setIsLoaded(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.ADMIN_USER_LIST,
      JSON.stringify(body),
      headers
    );

    props.setIsLoaded(false);
    if (!!data) {
      if (data.status == true) {
        setDataLIST(data.data);
        setTotalPages(data.totalPage);
        setTotalUsers(data.count);
      } else {
        // toast.error(data.message)
      }
    }
  };

  React.useEffect(() => {
    if (!!props.props.profile && !!props.props.profile.token) {
      accounttype();
    }
  }, [props.router]);
  React.useEffect(() => {
    if (!!props.props.profile && !!props.props.profile.token) {
      userLIST();
    }
  }, [props.props.profile, selectedPage]);

  return (
    <>
      <Grid container className={styles.contenar_grid_mddd}>
        <Grid item md={12}>
          <Box className={styles.box_main_dashboard}>
            <Box>
              <Typography className={styles.Dashboard_text_typo}>
                Dashboard
              </Typography>
              <Typography className={styles.typo_sub_data}>
                You can see the statistics of Goollooper here
              </Typography>
            </Box>
            <Divider />
            <Box className={styles.box_secend_in_tish_aaa}>
              <Box>
                <img src="/Group 1000003375.svg" />
                <Typography className={styles.cavunting_text_logg}>
                  {dataLIST_user}
                </Typography>
                <Typography className={styles.heding_total_userafa}>
                  Total Users
                </Typography>
              </Box>
              <Box>
                <img src="/Group 1000003375 (1).svg" />
                <Typography className={styles.cavunting_text_logg}>
                  9,043
                </Typography>
                <Typography className={styles.heding_total_userafa}>
                  Total Tasks Created
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.box_main_dashboard_two}>
            <Box>
              <Typography className={styles.Dashboard_text_typo}>
                Recent Users Signed Up
              </Typography>
              <Typography className={styles.typo_sub_data_two}>
                You can the recent signed up users here
              </Typography>
            </Box>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                className={styles.tebal_dashbords}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Reported User</TableCell>
                    <TableCell align="left">Email Address</TableCell>
                    <TableCell align="left">Phone Number</TableCell>
                    <TableCell align="left">Gender</TableCell>
                    <TableCell align="left">User Since</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datalist.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell scope="row">
                        <Box className={styles.box_img_ros_sss}>
                          <img src={row.img} />
                          <Typography>
                            {row.first_name + " " + row.last_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.mobile}</TableCell>
                      <TableCell align="left">{row.gender}</TableCell>
                      <TableCell align="left">{row.about_you}</TableCell>
                      <TableCell align="left">
                        {row.status == "active" ? (
                          <Typography className={styles.typo_active_status}>
                            {row.status}
                          </Typography>
                        ) : (
                          <Typography className={styles.office_typo_clor}>
                            {row.status}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Button className={styles.morevart_icon_btn}>
                          <MoreVertIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={styles.this_box_classs_noo}>
              {/* <Typography>Showing 1 to 6 of new entries</Typography> */}
              <Box
                className={styles.this_box_classs_noo}
                sx={{
                  width: "100%",
                }}
              >
                <Typography>
                  Showing {startNumber} to{" "}
                  {endNumber > totalUsers ? totalUsers : endNumber} of{" "}
                  {totalUsers > 0 ? totalUsers : 0} new entries
                </Typography>
                <Box className={styles.box_typo_paginehsann}>
                  {array.map((item, index) => {
                    return (
                      <>
                        <Button
                          className={styles.number_button}
                          sx={{
                            backgroundColor:
                              selectedPage == item + 1
                                ? "#FF7606 !important"
                                : "",
                            color:
                              selectedPage == item + 1 ? "#fff !important" : "",
                          }}
                          onClick={() => {
                            const totalNumber = totalUsers;
                            const pageSize = PAGE_LIMIT;
                            const numberOfParts = Math.ceil(
                              totalNumber / pageSize
                            );
                            const ranges = [...Array(numberOfParts).keys()].map(
                              (index) => {
                                const start = index * pageSize + 1;
                                const end = Math.min(
                                  (index + 1) * pageSize,
                                  totalNumber
                                );
                                const obj = {
                                  start: start,
                                  end: end,
                                };
                                return obj;
                              }
                            );

                            setStartNumber(ranges[item].start);
                            setEndNumber(ranges[item].end);
                            setSelectedPage(item + 1);
                          }}
                        >
                          {item + 1}
                        </Button>
                      </>
                    );
                  })}
                  <IconButton
                    disabled={selectedPage == 1 ? true : false}
                    onClick={() => {
                      if (selectedPage > 0) {
                        setSelectedPage(selectedPage - 1);
                        const totalCount = totalUsers;
                        const groupSize = PAGE_LIMIT;
                        setStartNumber(Math.max(startNumber - PAGE_LIMIT, 1));
                        const lastGroupSize = totalCount % groupSize;
                        if (endNumber == totalCount) {
                          setEndNumber(endNumber - lastGroupSize);
                        } else {
                          setEndNumber(
                            Math.max(
                              endNumber - PAGE_LIMIT,
                              PAGE_LIMIT > totalUsers ? totalUsers : PAGE_LIMIT
                            )
                          );
                        }
                      }
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <IconButton
                    disabled={totalPages == selectedPage ? true : false}
                    onClick={() => {
                      if (totalPages >= selectedPage) {
                        setSelectedPage(selectedPage + 1);
                        const totalCount = totalUsers;
                        const groupSize = PAGE_LIMIT;
                        const lastGroupSize = totalCount % groupSize;
                        setStartNumber(
                          Math.min(
                            startNumber + PAGE_LIMIT,
                            totalUsers - lastGroupSize + 1
                          )
                        );
                        setEndNumber(
                          Math.min(endNumber + PAGE_LIMIT, totalUsers)
                        );
                      }
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Dashboard;
