import React from "react";
import Layout from "../Layout";
import { Notifications } from "../Notification";

const PushNotification = ({ props, setIsLoaded }) => {
  return (
    <>
      <Layout>
        <Notifications />
      </Layout>
    </>
  );
};

export default PushNotification;
