import React from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./Support.module.scss";
import Checkbox from "@mui/material/Checkbox";
import ApiServices from "../../config/ApiServices";
import ApiEndpoint from "../../config/ApiEndpoint";
import { toast } from "react-toastify";
import moment from "moment/moment";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Support = ({ props, setIsLoaded }) => {
  const datalog = "Amet minim mollit non deserunt";
  const [listSupport, setListSupport] = React.useState([]);
  const [messageText, setMessageText] = React.useState("");
  const [selectedSupport, setSelectedSupport] = React.useState("");
  const [messagesList, setMessageList] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState("");
  const [idItem, setIdItem] = React.useState("");
  const [supportSearch, setSupportSearch] = React.useState([]);

  const supportList = async () => {
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.profile.token,
    };
    var body = {};
    setIsLoaded(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.SUPPORT_LIST,
      JSON.stringify(body),
      headers
    );
    setIsLoaded(false);
    if (!!data) {
      if (data.status == true) {
        // setListSupport()
        setListSupport(data.data);
        setSupportSearch(data.data);
      } else {
        // toast.error(data.message)
      }
    }
  };

  function truncateStringWithNumber(string, maxLength) {
    if (string) {
      if (string.length > maxLength) {
        return `${string.slice(0, maxLength)}...`;
      }
      return string;
    }
  }

  const onReadMessage = async (event) => {
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.profile.token,
    };
    var body = {
      id_support: selectedSupport.lastMessage.id_support,
    };
    setIsLoaded(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.ON_READ_MESSAGE,
      JSON.stringify(body),
      headers
    );
    if (data) {
      if (data.status) {
        supportList();
      }
    }
  };

  const onMessageLists = async (event) => {
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.profile.token,
    };
    var body = {
      id_support: selectedSupport.lastMessage.id_support,
    };
    setIsLoaded(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.VIEW_ADMIN_MESSAGELIST,
      JSON.stringify(body),
      headers
    );
    if (data) {
      if (data.status) {
        // supportList();
        setMessageList(data.data);
      }
    }
  };

  React.useEffect(() => {
    if (selectedSupport) {
      onReadMessage();
      onMessageLists();
    }
  }, [selectedSupport]);

  React.useEffect(() => {
    if (props && props.profile && props.profile.token) {
      supportList();
      if (props.profile.data && props.profile.data.id) {
        setCurrentUser(props.profile.data.id);
      }
    }
  }, [props.profile]);

  const handleUpload = async (event) => {
    setIsLoaded(true);
    var filename = event.target.files[0];
    var formData = new FormData();
    var headers = {
      // "Content-Type": "application/json",
      "x-access-token": props.profile.token,
    };
    if (event.target.files[0]) {
      formData.append("file", filename);
      formData.append("type", filename.type);
      var requestOptions = {
        method: "POST",
        body: formData,
        headers: headers,
      };

      const data = await fetch(ApiEndpoint.UPLOAD_FILE, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          return result;
        })
        .catch((error) => console.error("error", error));
      if (data) {
        if (data.status) {
          // toast.success("Image uploaded!");
          setIdItem(data.data.id);
          setIsLoaded(false);
        } else {
          toast.error(data.message);
          setIsLoaded(false);
        }
      } else {
        setIsLoaded(false);

        toast.error("Something went wrong!");
      }
    }
  };

  return (
    <>
      <Grid container className={styles.contenar_main_supprot}>
        <Grid item md={4} className={styles.box_grid_supp_name}>
          <Box className={styles.box_supooat_listscfss}>
            <Box className={styles.bxo_main_hedingdggd}>
              <Typography className={styles.add_support_text}>
                Support
              </Typography>
              <Typography className={styles.add_text_typo_youu}>
                You can chat users here
              </Typography>
            </Box>
            <Box className={styles.add_cade_input_texttt}>
              <input
                type="text"
                id="myserchbtn"
                name="search"
                placeholder="Search"
                className={styles.searchbtn}
                autoComplete="off"
                onChange={(event) => {
                  var value_ = event.target.value;
                  if (typeof value_ !== "object") {
                    if (!value_ || value_ == "") {
                      setListSupport(supportSearch);
                    } else {
                      var filteredData = supportSearch.filter((item) => {
                        let searchValue = (
                          item?.userDetail?.first_name +
                          " " +
                          item?.userDetail?.last_name
                        ).toLowerCase();
                        return searchValue.includes(
                          value_.toString().toLowerCase()
                        );
                      });
                      setListSupport(filteredData);
                    }
                  }
                }}
              />
            </Box>
            <Box className={styles.box_main_log_configg}>
              {listSupport.map((item, index) => {
                return (
                  <Button
                    onClick={() => {
                      setSelectedSupport(item);
                    }}
                  >
                    <Box>
                      <Avatar src={item?.userDetail?.profile_image} />
                    </Box>
                    <Box className={styles.inpoat_last_textsss}>
                      <Typography className={styles.name_fast_lasthshhs}>
                        {item?.userDetail?.first_name}{" "}
                        {item?.userDetail?.last_name}
                      </Typography>
                      <Typography className={styles.typo_send_texttata}>
                        {truncateStringWithNumber(
                          item?.lastMessage?.description,
                          25
                        )}
                      </Typography>
                    </Box>
                  </Button>
                );
              })}
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} className={styles.grid_maiin_suppatyy}>
          {selectedSupport ? (
            <Box className={styles.box_main_chart_senss}>
              <Box className={styles.box_name_pohot_deteles}>
                <Avatar />
                <Box className={styles.box_user_name_cruzz}>
                  <Typography>
                    {selectedSupport?.userDetail?.first_name}
                    {selectedSupport?.userDetail?.last_name}
                  </Typography>
                  <Box className={styles.box_checbox_closdioi}>
                    <Checkbox
                      className={styles.checbox_useaffaffa}
                      icon={<img src="/Rectangle 4907.svg" />}
                      {...label}
                    />
                    <Typography>Mark as complete</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className={styles.contenar_mass_dataaa}>
                {messagesList.map((item, index) => {
                  return (
                    <Box className={styles.rithe_side_box_texttst} key={index}>
                      <Typography>
                        {moment(item.createdAt).format("DD MMM YYYY hh:mm a")}
                      </Typography>
                      {currentUser == item.id_sender ? (
                        <>
                          <Box className={styles.box_rithe_new_tesffsffss}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                flexDirection: "column",
                                rowGap: "10px",
                              }}
                            >
                              {item.description ? (
                                <Box className={styles.typo_texggsggsggss}>
                                  <Typography>{item.description}</Typography>
                                </Box>
                              ) : (
                                ""
                              )}
                              {item.itmeList && item.itmeList.length > 0
                                ? item.itmeList.map((image, index) => {
                                    return (
                                      <Box
                                        key={index}
                                        sx={{
                                          width: "300px",
                                          height: "300px",
                                          "& img": {
                                            width: "100%",
                                          },
                                          "& video": {
                                            width: "100%",
                                          },
                                        }}
                                      >
                                        {image.type.includes("image") ? (
                                          <img src={image.image} />
                                        ) : image.type.includes("video") ? (
                                          <>
                                            <video autoPlay={true} controls>
                                              <source
                                                src={image.image}
                                                type={image.type}
                                              ></source>
                                            </video>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    );
                                  })
                                : ""}
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <Box className={styles.box_rithe_new_tesffsffss_two}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              rowGap: "10px",
                            }}
                          >
                            {item.description ? (
                              <Box className={styles.typo_texggsggsggss_two}>
                                <Typography>{item.description}</Typography>
                              </Box>
                            ) : (
                              ""
                            )}

                            {item.itmeList && item.itmeList.length > 0
                              ? item.itmeList.map((image, index) => {
                                  return (
                                    <Box
                                      key={index}
                                      sx={{
                                        width: "300px",
                                        height: "300px",
                                        "& img": {
                                          width: "100%",
                                        },
                                        "& video": {
                                          width: "100%",
                                        },
                                      }}
                                    >
                                      {image.type.includes("image") ? (
                                        <img src={image.image} />
                                      ) : image.type.includes("video") ? (
                                        <>
                                          <video autoPlay={true} controls>
                                            <source
                                              src={image.image}
                                              type={image.type}
                                            ></source>
                                          </video>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  );
                                })
                              : ""}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
              <form
                onSubmit={async (event) => {
                  event.preventDefault();
                  setMessageText("");
                  if (messageText || idItem) {
                    var headers = {
                      "Content-Type": "application/json",
                      "x-access-token": props.profile.token,
                    };

                    var body = {
                      id_support: selectedSupport.lastMessage.id_support,
                      type: "admin",
                    };
                    if (messageText) {
                      body.description = messageText;
                    }
                    if (idItem) {
                      body.id_item = [idItem];
                    }
                    setIsLoaded(true);
                    var data = await ApiServices.PostApiCall(
                      ApiEndpoint.ON_SEND_MESSAGE,
                      JSON.stringify(body),
                      headers
                    );
                    if (data) {
                      if (data.status) {
                        //   toast.success(data.message);
                        supportList();
                        setIdItem("");
                        onMessageLists();
                      } else {
                        toast.error(data.message);
                      }
                    }
                  }
                  setIsLoaded(false);
                }}
              >
                <Box className={styles.footer_box_input_fileee}>
                  <label for="inputTag" className={styles.lebal_fast_outputt}>
                    <img src="/Vector (15).svg" />
                    <input
                      id="inputTag"
                      type="file"
                      className={styles.addaffafagga}
                      onChange={handleUpload}
                    />
                  </label>

                  <TextField
                    placeholder="Send message"
                    value={messageText}
                    className={styles.text_filed_send_mass}
                    onChange={(event) => {
                      setMessageText(event.target.value);
                    }}
                  />
                  <Button type="submit">
                    <img src="/Vector (16).svg" />{" "}
                  </Button>
                </Box>
              </form>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default Support;
