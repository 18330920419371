import { Box } from "@mui/material";
import { Typography, styled } from "@mui/material";
import React from "react";

export const LableText = styled(Typography)(
  ({ color, fontSize, fontFamily }) => ({
    color: color || "#474747",
    fontFamily: "Roboto",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "13px" /* 118.182% */,
  })
);

export const Input_error = ({ text }) => {
  return (
    <Typography
      sx={{
        fontSize: "15px",
        color: "red",
        fontFamily: "Roboto-400",
        lineHeight: "1",
      }}
    >
      {text}
    </Typography>
  );
};

export const InputError = ({ error, touched }) => {
  return (
    <Box className={"input_error_view"}>
      {error && touched && <Typography>{error}</Typography>}
    </Box>
  );
};

export const Heading_text = styled(Typography)(({ fs, ta, wt }) => ({
  color: "#0A0A0A",
  fontFamily: "Roboto",
  fontSize: fs || "34px",
  fontStyle: "normal",
  fontWeight: "900",
  lineHeight: "41px" /* 120.588% */,
  textAlign: ta || "",
  width: wt || "",
}));

export const Small_Head_Text = styled(Typography)(({ ta, wt, fs, clr }) => ({
  // Create an account for your business
  color: clr || "#0A0A0A",
  fontSize: fs || "16px",
  fontFamily: "Roboto",
  fontWeight: "400",
  lineHeight: "22px",
  wordWrap: "break-word",
  width: wt || "",
  textAlign: ta || "",
}));

export const Small_Head_Bold_Text = styled(Typography)(
  ({ ta, wt, fs, tt }) => ({
    // Create an account for your business
    color: "#0A0A0A",
    fontSize: fs || "16px",
    fontFamily: "Roboto",
    fontWeight: "600",
    lineHeight: "22px",
    wordWrap: "break-word",
    width: wt || "",
    textAlign: ta || "",
    textTransform: tt || "",
  })
);

export const BodyText1 = styled(Typography)(({ fs }) => ({
  // By signing up, I agree to the
  color: "#474747",
  fontSize: fs || "15px",
  fontFamily: "Roboto",
  fontWeight: "400",
  lineHeight: "20px",
  wordWrap: "break-word",
  textAlign: "center",
}));

export const EmptyData = () => {
  return <Small_Head_Text>No Data found</Small_Head_Text>;
};
