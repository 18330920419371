import Constants from "./Constants";

 const endpoints = {
  LOGIN_USER: Constants.BASE_API_URL + "admin/login",
  FORGOT_PASSWORD: Constants.BASE_API_URL + "admin/forgotpassword",
  VERIFICATIONCODE_ORGANIZATION: Constants.BASE_API_URL + "organisation/verify/code",
  RESEND_CODE: Constants.BASE_API_URL + "admin/resend/code",
  RESET_PASSWORD: Constants.BASE_API_URL + "organisation/resetpassword",
  CREATE_PASSWORD: Constants.BASE_API_URL + "admin/createpassword",
  ACTIVE_DEACTIVE_USER: Constants.BASE_API_URL + "admin/user/statusupdate",
  USER_TOTEL_DATA: Constants.BASE_API_URL + "admin/user/total/count",
  ADMIN_USER_LIST: Constants.BASE_API_URL + "admin/user/list",
  FIXTURE_REPORT: Constants.BASE_API_URL + "admin/fixture/report/list",
  UMPHIRE_REPORT: Constants.BASE_API_URL + "admin/umphire/report/list",
  SUBADMIN_ADD: Constants.BASE_API_URL + "admin/subadmin/add",
  USER_PROFILE: Constants.BASE_API_URL + "umphire/view/profile",
  GUIDELINES_PAGE: Constants.BASE_API_URL + "admin/guildline/",
  UPDATE_ADD: Constants.BASE_API_URL + "admin/guidline/add",
  SPORT_LIST_COMMON: Constants.BASE_API_URL + "user/sport/list",
  // verifier
  VERIFIER_PROFILE: Constants.BASE_API_URL + "verifier/profile/view",
  //scholls 
  ORG_PROFILE: Constants.BASE_API_URL + "organisation/profile/view",
  UMPHIRE_DELETE: Constants.BASE_API_URL + "admin/umphire/delete",
  FIXTURE_DELETE: Constants.BASE_API_URL + "organisation/fixture/delete",
  SESSION_LIST: Constants.BASE_API_URL + "admin/user/alllist",
  JOBS_LIST: Constants.BASE_API_URL + "admin/user/alllist",
  //advertisement
  ADVERTISEMENT_VIEW : Constants.BASE_API_URL + "admin/advertisement/view" ,
  ADVERTISEMENT_LIST : Constants.BASE_API_URL + "admin/advertisement/list" ,
  ADD_ADVERTISEMENT : Constants.BASE_API_URL + "admin/advertisement/add" ,
  EDIT_ADVERTISEMENT : Constants.BASE_API_URL + "admin/advertisement/edit" ,
  DELETE_ADVERTISEMENT : Constants.BASE_API_URL + "admin/advertisement/delete" ,
  ROLES_LIST : Constants.BASE_API_URL + "user/roles" ,

  // doc
  UPDATE_DOC:Constants.BASE_API_URL + "umphire/document/verify",

  // // INTEREST
  // INTEREST_LIST: Constants.BASE_API_URL + "admin/interest/list",
  // DELETE_INTEREST: Constants.BASE_API_URL + "admin/interest/delete",
  // EDIT_INTEREST: Constants.BASE_API_URL + "admin/interest/edit",
  // ADD_INTEREST: Constants.BASE_API_URL + "admin/interest/add",
  // VIEW_INTEREST: Constants.BASE_API_URL + "admin/interest/view",
  // DELETE_CATEGORY_INTEREST:
  //   Constants.BASE_API_URL + "admin/interest/category/delete",
  // ADD_CATEGORY_INTEREST: Constants.BASE_API_URL + "admin/interest/category/add",

  // VOLUNTEERS_LIST: Constants.BASE_API_URL + "admin/volunteer/list",
  // ADD_VOLUNTEER: Constants.BASE_API_URL + "admin/volunteer/add",
  // DELETE_VOLUNTEER: Constants.BASE_API_URL + "admin/volunteer/delete",
  // EDIT_VOLUNTEER: Constants.BASE_API_URL + "admin/volunteer/edit",
  // GET_CATEGORY_VOLUNTEER: Constants.BASE_API_URL + "admin/volunteer/view",
  // DELETE_CATEGORY_VOLUNTEER:
  //   Constants.BASE_API_URL + "admin/volunteer/category/delete",
  // ADD_CATEGORY_VOLUNTEER:
  //   Constants.BASE_API_URL + "admin/volunteer/category/add",

  // SUPPORT_LIST: Constants.BASE_API_URL + "admin/support/ticket/list",
  // ON_SEND_MESSAGE: Constants.BASE_API_URL + "user/support/send",
  // ON_READ_MESSAGE: Constants.BASE_API_URL + "admin/support/message/read",
  // VIEW_ADMIN_MESSAGELIST: Constants.BASE_API_URL + "user/support/ticket/view",

  // UPLOAD FILE
  UPLOAD_FILE: Constants.BASE_API_URL + "upload/file",
  UPLOAD:Constants.BASE_API_URL +"user/upload"
};

export default endpoints;