import styles from "./Sub_Admins.module.scss";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  createTheme,
  Divider,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiServices from "../../config/ApiServices";
import ApiEndpoint from "../../config/ApiEndpoint";
import { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { toast } from "react-toastify";
import Pagination from "../Pagination/pagination";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { InputBox, Input_field, Input_lable } from "../Shareable/input";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

let PageSize = 6;
const Sub_Admins = (props) => {
  const PAGE_LIMIT = 10;
  const [open, setOpen] = React.useState(false);
  const [open_two, setOpen_two] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    // USER_data()
  };
  const [currentPageFlaged, setCurrentPageFlaged] = useState(1);
  const [datalist, setDataLIST] = useState([]);
  const [data_porfele, setData_porfile] = useState();
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState("");
  const [totalUsers, setTotalUsers] = React.useState("");
  const [startNumber, setStartNumber] = React.useState(1);
  const [endNumber, setEndNumber] = React.useState(PAGE_LIMIT);
  const array = [...Array(totalPages).keys()];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subAdminSearch, setSubAdminSearch] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [gender, setGender] = React.useState("");
  const [apiQuery, setApiQuery] = React.useState({
    gender: "",
    status: "",
    selectedDate: "",
  });
  let navigate = useNavigate();

  //SUBADMIN_LIST SUBADMIN_ADD

  const userLIST = async () => {
    var body = {
      page: selectedPage,
      limit: PAGE_LIMIT,
      pagination: true,
    };

    if (selectedDate) {
      body.date = selectedDate;
    }

    if (gender) {
      body.gender = gender;
    }

    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.props.profile.token,
    };
    props.setIsLoaded(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.FIXTURE_REPORT,
      JSON.stringify(body),
      headers
    );

    props.setIsLoaded(false);
    if (!!data) {
      if (data.status == true) {
        let dataArr = data.data;

        if (status) {
          const arr = [];
          if (data.data) {
            for (let index = 0; index < data.data.length; index++) {
              const element = data.data[index];
              if (status == element.status) {
                arr.push(element);
              }
            }
          }
          dataArr = arr;
        }

        setDataLIST(dataArr);
        setTotalPages(data.totalPage);
        setTotalUsers(data.count);
        setSubAdminSearch(data.data);
      } else {
        setDataLIST([]);
        // toast.error(data.message)
      }
    }
  };

  const fixtureDelete = async (id) => {
    var body = {
      id_fixture: id,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.props.profile.token,
    };
    props.setIsLoaded(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.FIXTURE_DELETE,
      JSON.stringify(body),
      headers
    );

    props.setIsLoaded(false);
    if (!!data) {
      if (data.status == true) {
        userLIST();
      } else {
        // toast.error(data.message)
      }
    }
  };
  const userADD = async () => {
    var body = {
      first_name: formik.values.First_Name,
      last_name: formik.values.Last_Name,
      email: formik.values.Email_Address,
      password: formik.values.Temporary_Password,
      mobile: formik.values.Phone_Number,
      location: formik.values.Location,
      birth_date: formik.values.Date_Birth,
      birth_date: moment(formik.values.Date_Birth).format("L"),
      role: "sub_admin",
    };
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.props.profile.token,
    };
    props.setIsLoaded(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.SUBADMIN_ADD,
      JSON.stringify(body),
      headers
    );

    props.setIsLoaded(false);
    if (data.status == true) {
      toast.success(data.message);
      userLIST();
    } else {
      toast.error(data.message);
    }
  };

  React.useEffect(() => {
    if (!!props.props.profile && !!props.props.profile.token) {
      userLIST();
    }
  }, [props.props.profile, selectedPage, selectedDate, status, gender]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen_two = () => {
    setOpen_two(true);
  };

  const handleClose_two = () => {
    setOpen_two(false);
  };
  const formik = useFormik({
    initialValues: {
      First_Name: "",
      Last_Name: "",
      Date_Birth: "",
      Location: "",
      Email_Address: "",
      Temporary_Password: "",
    },
    validationSchema: Yup.object({
      First_Name: Yup.string().max(255).required("First Name is required"),
      Last_Name: Yup.string().max(255).required("Last Name is required"),
      Date_Birth: Yup.string().max(255).required("Date of Birth is required"),
      Location: Yup.string().max(255).required("Location is required"),
      Email_Address: Yup.string().required("Email Address is required"),
      Temporary_Password: Yup.string().required(
        "Temporary Password is required"
      ),
      Phone_Number: Yup.number().required("Phone Number is required"),
    }),
    onSubmit: () => {
      userADD();
      handleClose_two();
    },
  });

  const themeline = createTheme({ palette: { primary: { main: "#FF7606" } } });
  return (
    <>
      <Dialog
        open={anchorEl}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          setAnchorEl(false);
        }}
      >
        <Box className={styles.main_box_dialog}>
          <Box className={styles.insideBox}>
            <Grid
              container
              sx={{
                "& .MuiGrid-item": {
                  padding: "5px",
                },
              }}
            >
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontSize: "20px",
                  }}
                >
                  Filter
                </Typography>
              </Grid>

              <Grid item md={6}>
                <InputBox>
                  <Input_lable>Status</Input_lable>
                  <RadioGroup
                    value={apiQuery.status}
                    onChange={(event) => {
                      const apiQueryObj = { ...apiQuery };
                      apiQueryObj.status = event.target.value;
                      setApiQuery(apiQueryObj);
                    }}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      "& .MuiFormControlLabel-root": {
                        padding: "0px",
                      },
                    }}
                  >
                    <FormControlLabel
                      value="active"
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value="offline"
                      control={<Radio />}
                      label="Offline"
                    />
                  </RadioGroup>
                </InputBox>
              </Grid>
              <Grid item md={6}>
                <InputBox>
                  <Input_lable>Gender</Input_lable>

                  <RadioGroup
                    name="radio-buttons-group"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      "& .MuiFormControlLabel-root": {
                        padding: "0px",
                      },
                    }}
                    value={apiQuery.gender}
                    onChange={(event) => {
                      const apiQueryObj = { ...apiQuery };
                      apiQueryObj.gender = event.target.value;
                      setApiQuery(apiQueryObj);
                    }}
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                </InputBox>
              </Grid>
              <Grid item md={6}>
                <InputBox>
                  <Input_lable>Date</Input_lable>

                  <Input_field
                    type="date"
                    value={apiQuery.selectedDate}
                    onChange={(event) => {
                      const apiQueryObj = { ...apiQuery };
                      apiQueryObj.selectedDate = moment(
                        event.target.value
                      ).format("YYYY-MM-DD");
                      setApiQuery(apiQueryObj);
                    }}
                  />
                </InputBox>
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  columnGap: "10px",
                }}
              >
                <Button
                  className={styles.cancle_button}
                  onClick={() => {
                    setAnchorEl(false);
                    const defaultobj = {
                      premiumUser: "",
                      gender: "",
                      accountType: "",
                    };

                    setApiQuery(defaultobj);
                    setStatus("");
                    setSelectedDate("");
                    setGender("");
                  }}
                >
                  Clear
                </Button>
                <Button
                  className={styles.cancle_button}
                  onClick={() => {
                    setAnchorEl(false);
                    setStatus(apiQuery.status);
                    setSelectedDate(apiQuery.selectedDate);
                    setGender(apiQuery.gender);
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>

      <Box className={styles.contenar_grid_mddd}>
        <Dialog
          fullWidth={true}
          maxWidth={"xs"}
          open={open}
          onClose={handleClose}
        >
          <Box className={styles.box_class_in_fast_popup}>
            <Box className={styles.typo_text_hedingg}>
              <Box></Box>
              <Typography>Sub Admin’s Profile</Typography>
              <Button onClick={handleClose}>
                <CloseIcon />
              </Button>
            </Box>
            <Box className={styles.box_img_an_ditelas}>
              <img
                width={99}
                height={99}
                src={!!data_porfele ? data_porfele.profile_image : ""}
              />
              <Typography className={styles.typo_name_detelas}>
                {!!data_porfele ? data_porfele.first_name : "" + " "}{" "}
                {!!data_porfele ? data_porfele.last_name : ""}
              </Typography>
              <Typography className={styles.typo_datae_aaaa}>
                {moment(!!data_porfele ? data_porfele.birth_date : "").format(
                  "ll"
                )}
              </Typography>
              <Typography className={styles.not_tasker_typooo}>
                {!!data_porfele && data_porfele.role == "sub_admin"
                  ? "Sub Admin Since"
                  : ""}
              </Typography>
            </Box>
            <Box className={styles.box_in_info_email}>
              <Typography className={styles.info_text_personn}>
                Personal Info
              </Typography>
              <Box className={styles.info_box_pohoneee}>
                <Box className={styles.box_email_last}>
                  <Typography className={styles.heding_emakakaaa}>
                    Email Address
                  </Typography>
                  <Typography className={styles.deelassss_Ssss}>
                    {!!data_porfele ? data_porfele.email : ""}
                  </Typography>
                </Box>
                <Box className={styles.box_email_last}>
                  <Typography className={styles.heding_emakakaaa}>
                    Phone Number
                  </Typography>
                  <Typography className={styles.deelassss_Ssss}>
                    {!!data_porfele ? data_porfele.mobile : ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Dialog>
        <Dialog maxWidth={"md"} open={open_two} onClose={handleClose_two}>
          <Box className={styles.Dialog_styles_popupp}>
            <Typography className={styles.add_typo_admin_heding}>
              Add Sub Admin
            </Typography>
            <Typography className={styles.sub_titel_hedinhgg}>
              This will be reflected to sub admin list and they have the ability
              to control the admin.
            </Typography>
            <Divider />
            <form onSubmit={formik.handleSubmit} className={styles.formedit}>
              <Grid container className={styles.grid_contenar_maindd}>
                <Grid item md={6} className={styles.fast_grid_typo_data}>
                  <Typography className={styles.heding_kjdjjjdjd}>
                    Personal Data
                  </Typography>
                  <Box className={styles.bxo_input_lebal_data}>
                    <Typography className={styles.lebal_input_lineee}>
                      First Name
                    </Typography>
                    <TextField
                      error={Boolean(
                        formik.touched.First_Name && formik.errors.First_Name
                      )}
                      helperText={
                        formik.touched.First_Name && formik.errors.First_Name
                      }
                      name="First_Name"
                      className={styles.inputnamelist}
                      placeholder="Type here"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.First_Name}
                    ></TextField>
                  </Box>
                  <Box className={styles.bxo_input_lebal_data}>
                    <Typography className={styles.lebal_input_lineee}>
                      Last Name
                    </Typography>
                    <TextField
                      error={Boolean(
                        formik.touched.Last_Name && formik.errors.Last_Name
                      )}
                      helperText={
                        formik.touched.Last_Name && formik.errors.Last_Name
                      }
                      name="Last_Name"
                      className={styles.inputnamelist}
                      placeholder="Type here"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.Last_Name}
                    ></TextField>
                  </Box>
                  <Box className={styles.bxo_input_lebal_data}>
                    <Typography className={styles.lebal_input_lineee}>
                      Date of Birth
                    </Typography>
                    <TextField
                      error={Boolean(
                        formik.touched.Date_Birth && formik.errors.Date_Birth
                      )}
                      helperText={
                        formik.touched.Date_Birth && formik.errors.Date_Birth
                      }
                      name="Date_Birth"
                      className={styles.inputnamelist}
                      placeholder="Type here"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.Date_Birth}
                    ></TextField>
                  </Box>
                  <Box className={styles.bxo_input_lebal_data}>
                    <Typography className={styles.lebal_input_lineee}>
                      Location
                    </Typography>
                    <TextField
                      error={Boolean(
                        formik.touched.Location && formik.errors.Location
                      )}
                      helperText={
                        formik.touched.Location && formik.errors.Location
                      }
                      name="Location"
                      className={styles.inputnamelist}
                      placeholder="Type here"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.Location}
                    ></TextField>
                  </Box>
                </Grid>
                <Grid item md={6} className={styles.grid_two_main_grid_acc}>
                  <Typography className={styles.heding_kjdjjjdjd}>
                    Account Information
                  </Typography>
                  <Box className={styles.bxo_input_lebal_data}>
                    <Typography className={styles.lebal_input_lineee}>
                      Email Address
                    </Typography>
                    <TextField
                      error={Boolean(
                        formik.touched.Email_Address &&
                          formik.errors.Email_Address
                      )}
                      helperText={
                        formik.touched.Email_Address &&
                        formik.errors.Email_Address
                      }
                      name="Email_Address"
                      className={styles.inputnamelist}
                      placeholder="Type here"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.Email_Address}
                    ></TextField>
                  </Box>
                  <Box className={styles.bxo_input_lebal_data}>
                    <Typography className={styles.lebal_input_lineee}>
                      Phone Number
                    </Typography>
                    <TextField
                      error={Boolean(
                        formik.touched.Phone_Number &&
                          formik.errors.Phone_Number
                      )}
                      helperText={
                        formik.touched.Phone_Number &&
                        formik.errors.Phone_Number
                      }
                      name="Phone_Number"
                      type="text"
                      className={styles.inputnamelist}
                      placeholder="+123"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.Phone_Number}
                    ></TextField>
                  </Box>
                  <Box className={styles.bxo_input_lebal_data}>
                    <Typography className={styles.lebal_input_lineee}>
                      Temporary Password
                    </Typography>
                    <TextField
                      error={Boolean(
                        formik.touched.Temporary_Password &&
                          formik.errors.Temporary_Password
                      )}
                      helperText={
                        formik.touched.Temporary_Password &&
                        formik.errors.Temporary_Password
                      }
                      name="Temporary_Password"
                      className={styles.inputnamelist}
                      placeholder="Type here"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.Temporary_Password}
                    ></TextField>
                  </Box>
                </Grid>
                <Grid item md={12} className={styles.grid_main_two_btnnn}>
                  <Button className={styles.add_btn_popup_tish} type="submit">
                    Add
                  </Button>
                  <Button
                    className={styles.cancel_btn_hshhsggs}
                    onClick={() => {
                      handleClose_two();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Dialog>
        <Box>
          <Box className={styles.box_main_dashboard_two}>
            <Box>
              <Typography className={styles.Dashboard_text_typo}>
                Report
              </Typography>
              <Typography className={styles.typo_sub_data_two}>
                You can see the overall Report of umphire here
              </Typography>
            </Box>
            <Box className={styles.main_div_filtefaa}>
              <Box className={styles.box_btn_input}>
                {/* <Button
                  className={styles.filter_img_btn_fill}
                  onClick={() => {
                    setAnchorEl(true);
                  }}
                >
                  <img src="/Filter.svg" />
                  Filters
                </Button> */}
                <input
                  type="text"
                  id="myserchbtn"
                  name="search"
                  placeholder="Search"
                  className={styles.searchbtn}
                  autoComplete="off"
                  onChange={(event) => {
                    var value_ = event.target.value;
                    if (typeof value_ !== "object") {
                      if (!value_ || value_ == "") {
                        setDataLIST(subAdminSearch);
                      } else {
                        var filteredData = subAdminSearch.filter((item) => {
                          let searchValue = (
                            item?.fixtureDetails?.name 
                          ).toLowerCase();
                          return searchValue.includes(
                            value_.toString().toLowerCase()
                          );
                        });
                        setDataLIST(filteredData);
                      }
                    }
                  }}
                />
              </Box>
              {/* <Box>
                <Button
                  className={styles.add_sub_btn_aaa}
                  onClick={handleClickOpen_two}
                >
                  {" "}
                  + Add Sub Admin
                </Button>
              </Box> */}
            </Box>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                className={styles.tebal_dashbords}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Session</TableCell>
                    <TableCell align="left">Reported By</TableCell>
                    <TableCell align="left">Reason</TableCell>
                    {/* <TableCell align="left">Gender</TableCell>
                    <TableCell align="left">User Since</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left"></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datalist.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell scope="row">
                        <Box className={styles.box_img_ros_sss}>
                          <Typography>
                            {row?.fixtureDetails?.name } ({row?.fixtureDetails?.sport_name })
                          </Typography>
                        </Box>
                      </TableCell>
                      {/* <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.mobile}</TableCell>
                      <TableCell align="left">{row.gender}</TableCell> */}
                      <TableCell
                      scope="row"
                      onClick={() => {
                        navigate("/userdetails?id=" +row.reporterUser.id + "&role=" + row.reporterUser.role);
                      }}
                    >
                      <Box className={styles.box_img_ros_sss}>
                        {row.reporterUser.profile_image ? <img src={row.reporterUser.profile_image}  style={{width: "50px", height: "50px", objectFit: "cover"}}/>:""}
                        <Typography>
                          {row.reporterUser.first_name + " " + row.reporterUser.last_name}
                        </Typography>
                      </Box>
                    </TableCell>
                      <TableCell align="left">{row?.reason}</TableCell>
                     
                      <TableCell align="left">
                      <IconButton
                        onClick={() => fixtureDelete(row.id_fixure)}
                        className={styles.deleteIcon}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Box className={styles.this_box_classs_noo}>
          <Typography>
            Showing {startNumber} to{" "}
            {endNumber > totalUsers ? totalUsers : endNumber} of{" "}
            {totalUsers > 0 ? totalUsers : 0} new entries
          </Typography>
          <Box className={styles.box_typo_paginehsann}>
            {array.map((item, index) => {
              return (
                <>
                  <Button
                    className={styles.number_button}
                    sx={{
                      backgroundColor:
                        selectedPage == item + 1 ? "#088164 !important" : "",
                      color: selectedPage == item + 1 ? "#fff !important" : "",
                    }}
                    onClick={() => {
                      const totalNumber = totalUsers;
                      const pageSize = PAGE_LIMIT;
                      const numberOfParts = Math.ceil(totalNumber / pageSize);
                      const ranges = [...Array(numberOfParts).keys()].map(
                        (index) => {
                          const start = index * pageSize + 1;
                          const end = Math.min(
                            (index + 1) * pageSize,
                            totalNumber
                          );
                          const obj = {
                            start: start,
                            end: end,
                          };
                          return obj;
                        }
                      );

                      setStartNumber(ranges[item].start);
                      setEndNumber(ranges[item].end);
                      setSelectedPage(item + 1);
                    }}
                  >
                    {item + 1}
                  </Button>
                </>
              );
            })}
            <IconButton
              disabled={selectedPage == 1 ? true : false}
              onClick={() => {
                if (selectedPage > 0) {
                  setSelectedPage(selectedPage - 1);
                  const totalCount = totalUsers;
                  const groupSize = PAGE_LIMIT;
                  setStartNumber(Math.max(startNumber - PAGE_LIMIT, 1));
                  const lastGroupSize = totalCount % groupSize;
                  if (endNumber == totalCount) {
                    setEndNumber(endNumber - lastGroupSize);
                  } else {
                    setEndNumber(
                      Math.max(
                        endNumber - PAGE_LIMIT,
                        PAGE_LIMIT > totalUsers ? totalUsers : PAGE_LIMIT
                      )
                    );
                  }
                }
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton
              disabled={totalPages == selectedPage ? true : false}
              onClick={() => {
                if (totalPages >= selectedPage) {
                  setSelectedPage(selectedPage + 1);
                  const totalCount = totalUsers;
                  const groupSize = PAGE_LIMIT;
                  const lastGroupSize = totalCount % groupSize;
                  setStartNumber(
                    Math.min(
                      startNumber + PAGE_LIMIT,
                      totalUsers - lastGroupSize + 1
                    )
                  );
                  setEndNumber(Math.min(endNumber + PAGE_LIMIT, totalUsers));
                }
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Sub_Admins;
