import {
    Box,
    Button,
    Grid,
    Typography,
    createTheme,
    Menu,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
    IconButton,
  } from "@mui/material";
  import styles from "./advertisement.module.scss";
  import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
  import * as React from "react";
  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableCell from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import MoreVertIcon from "@mui/icons-material/MoreVert";
  // import Pagination from '@mui/material/Pagination';
  import Dialog from "@mui/material/Dialog";
  import CloseIcon from "@mui/icons-material/Close";
  import ApiServices from "../../config/ApiServices";
  import ApiEndpoint from "../../config/ApiEndpoint";
  import { useState } from "react";
  import { InputBox, Input_field, Input_lable } from "../Shareable/input";
  import { toast } from "react-toastify";
  import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
  import { getTimeDifferenceMessage } from "../../Utils/func";
  import { useNavigate } from "react-router-dom";
  import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
  // import { Edit, Delete } from '@mui/icons-material';
  import Tooltip from '@mui/material/Tooltip';
  import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DeleteIcon from "../../assets/images/delete-btn.svg"
import EditIcon from "../../assets/images/edit-btn.svg"
const MySwal = withReactContent(Swal);
  
  const Advertisement = (props) => {
    const PAGE_LIMIT = 10;
    const [open, setOpen] = React.useState(false);
    const [datalist, setDataLIST] = useState([]);
    const [advertisementList, setAdvertisementList] = useState([]);
    const [open_two, setOpen_two] = React.useState(false);
    const [data_porfele, setData_porfile] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filterName, setFilterName] = React.useState("");
    const [premiumUser, setPremiumUser] = React.useState("");
    const [accountType, setAccountType] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState("");
    const [totalUsers, setTotalUsers] = React.useState("");
    const [startNumber, setStartNumber] = React.useState(1);
    const [endNumber, setEndNumber] = React.useState(PAGE_LIMIT);
    const [userSearch, setUserSearch] = React.useState([]);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    let navigate = useNavigate();
  
    const [apiQuery, setApiQuery] = React.useState({
      premiumUser: "",
      gender: "",
      accountType: "",
      name: "",
    });
  
    const array = [...Array(totalPages).keys()];
   

    
    const advertisementLIST = async () => {
      var body = {
        type: "advertisement",
        pagination: true,
        page: selectedPage,
        limit: PAGE_LIMIT,
        search: debouncedSearch,
      };

      if (!!accountType) {
        body.user_type = accountType;
      }
    
      var headers = {
        "Content-Type": "application/json",
        "x-access-token": props.props.profile.token,
      };
    
      props.setIsLoaded(true);
    
      var endpoint = ApiEndpoint.ADVERTISEMENT_LIST;
    
      try {
        const response = await ApiServices.PostApiCall(endpoint, JSON.stringify(body), headers);
        toast.dismiss();
        if (response.status == true) {
          setAdvertisementList(response.data)
          setTotalPages(response.totalPage);
          // setUserSearch(response.data);
          setTotalUsers(response.count);
        } else {
          setAdvertisementList([]);
          toast.dismiss();
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data");
      } finally {
        props.setIsLoaded(false);
      }
    };
  
    const handleDelete = async (id) => {
      const result = await MySwal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this advertisement?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      });
  
      if (result.isConfirmed) {
        var body = {
          id: id,
        };
        var headers = {
          "Content-Type": "application/json",
          "x-access-token": props.props.profile.token,
        };
        props.setIsLoaded(true);
        try {
          const response = await ApiServices.PostApiCall(
            ApiEndpoint.DELETE_ADVERTISEMENT,
            JSON.stringify(body),
            headers
          );
  
          if (response.status == true) {
            advertisementLIST();
            MySwal.fire(
              'Deleted!',
              'Your advertisement has been deleted.',
              'success'
            );
          } else {
            MySwal.fire(
              'Failed!',
              response.message,
              'error'
            );
          }
        } catch (error) {
          console.error("Error deleting advertisement:", error);
          MySwal.fire(
            'Error!',
            'An error occurred while deleting the advertisement.',
            'error'
          );
        } finally {
          props.setIsLoaded(false);
        }
      } else {
        MySwal.fire(
          'Cancelled',
          'Your advertisement is safe :)',
          'error'
        );
      }
    };
    React.useEffect(() => {
      if (!!props.props.profile && !!props.props.profile.token) {
        advertisementLIST();
      }
    }, [
      props.props.profile,
      selectedPage,
      premiumUser,
      accountType,
      gender,
      filterName,
      debouncedSearch
    ]);

    React.useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedSearch(userSearch);
      }, 800); 
  
      return () => {
        clearTimeout(handler);
      };
    }, [userSearch]);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleClickOpen_two = () => {
      setOpen_two(true);
    };
  
    const handleClose_two = () => {
      setOpen_two(false);
    };
    const themeline = createTheme({ palette: { primary: { main: "#FF7606" } } });
  
    const handleEdit = (id) => {
      navigate(`/edit-advertisement/${id}`);
  };


  
    return (
      <>
     
        <Box className={styles.contenar_grid_mddd}>
          <Box className={styles.box_main_dashboard_two}>
                  <Box>
                  <Typography className={styles.Dashboard_text_typo}>
                  Advertisements
                  </Typography>
                  <Typography className={styles.typo_sub_data_two}>
                    You can see the overall advertisement here
                  </Typography>
                  </Box>

                {/* Filter , Search , Add  */}
                <Box className={styles.box_btn_input} >
                    <Button
                  className={styles.filter_img_btn_fill}
                  onClick={() => {
                    setAnchorEl(true);
                  }}
                >
                  <img src="/Filter.svg" />
                  Filters
                </Button>
                  <input
                    type="text"
                    id="myserchbtn"
                    name="search"
                    placeholder="Search by title or sport"
                    className={styles.searchbtn}
                    autoComplete="off"
                    onChange={(event) => {
                      setUserSearch(event.target.value);
                  }}
                  />
                  <Button
                  className={styles.addbtn}
                  variant="contained" 
                  color="primary"
                  onClick={() => {
                  navigate('/add-advertisement')
                  }}
                  >
                    Add
                  </Button>
                </Box>

                <Dialog
        open={anchorEl}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          setAnchorEl(false);
        }}
      >
        <Box className={styles.main_box_dialog}>
          <Box className={styles.insideBox}>
            <Grid
              container
              sx={{
                "& .MuiGrid-item": {
                  padding: "5px",
                },
              }}
            >
              <Grid item md={12}>
                <InputBox>
                  <Input_lable>Role</Input_lable>

                  <RadioGroup
                    value={apiQuery.accountType}
                    onChange={(event) => {
                      const apiQueryObj = { ...apiQuery };

                      apiQueryObj.accountType = event.target.value;
                      setApiQuery(apiQueryObj);
                    }}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      "& .MuiFormControlLabel-root": {
                        padding: "0px",
                      },
                    }}
                  >
                    <FormControlLabel
                      value="umphires"
                      control={<Radio />}
                      label="Umpire"
                    />
                      <FormControlLabel
                      value="coach"
                      control={<Radio />}
                      label="Coach"
                    />
                      <FormControlLabel
                      value="strength&conditioning_coach"
                      control={<Radio />}
                      label="Strength and Conditioning coach"
                    />
                     <FormControlLabel
                      value="sports_psychologist"
                      control={<Radio />}
                      label="Physio or Sports Psychologist"
                    />
                  </RadioGroup>
                </InputBox>
              </Grid>
              {/* <Grid item md={6}>
                <InputBox>
                  <Input_lable>Gender</Input_lable>

                  <RadioGroup
                    name="radio-buttons-group"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      "& .MuiFormControlLabel-root": {
                        padding: "0px",
                      },
                    }}
                    value={apiQuery.gender}
                    onChange={(event) => {
                      const apiQueryObj = { ...apiQuery };
                      apiQueryObj.gender = event.target.value;
                      setApiQuery(apiQueryObj);
                    }}
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                </InputBox>
              </Grid> */}
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  columnGap: "10px",
                }}
              >
                <Button
                  className={styles.cancle_button}
                  onClick={() => {
                    setAnchorEl(false);
                    const defaultobj = {
                      premiumUser: "",
                      gender: "",
                      accountType: "",
                    };

                    setApiQuery(defaultobj);
                    setAccountType("");
                    setPremiumUser("");
                    setGender("");
                    setFilterName("");
                  }}
                >
                  Clear
                </Button>
                <Button
                  className={styles.cancle_button}
                  onClick={() => {
                    setAnchorEl(false);
                    setAccountType(apiQuery.accountType);
                    setPremiumUser(apiQuery.premiumUser);
                    setFilterName(apiQuery.name);
                    setGender(apiQuery.gender);
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
              </Dialog>

                 {/* Table */}

                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    className={styles.tebal_dashbords}
                    aria-label="simple table"
                  >
      
                    <TableHead>
                      <TableRow >
                        <TableCell style={{ width: '15%' }} align="left">Title</TableCell>
                        <TableCell style={{ width: '15%' }} align="left">Role</TableCell>
                        <TableCell style={{ width: '15%' }} align="left">Website Link</TableCell>
                        <TableCell style={{ width: '15%' }} align="left">Sport Type</TableCell>
                        <TableCell style={{ width: '15%' }} align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
       
      
                    <TableBody>
                      {advertisementList.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell scope="row" >
                            <Box className={styles.box_img_ros_sss}>
                             <Typography> {row?.name} </Typography>
                            </Box>
                          </TableCell>
                          <TableCell style={{ width: '15%' }} align="left">{row?.user_type}</TableCell>
                          <TableCell style={{ width: '15%', wordBreak:'break-all'}} align="left">{row?.url_link}</TableCell>
                          <TableCell style={{ width: '15%' }} align="left">{row?.advertisementSport.name}</TableCell>
                          <TableCell style={{ width: '15%' }} align="left">
                          <Tooltip title="Edit">
                            <IconButton onClick={() => handleEdit(row?.id)}>
                              <img src={EditIcon} alt="Edit" style={{ width: '20px', height: '20px' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => handleDelete(row?.id)}>
                              <img src={DeleteIcon} alt="Delete" style={{ width: '20px', height: '20px' }} />
                            </IconButton>
                          </Tooltip>
                            </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>


          </Box>


          {/* Pagination */}
          <Box className={styles.this_box_classs_noo}>
            <Typography>
              Showing {startNumber} to {endNumber} of{" "}
              {totalUsers > 0 ? totalUsers : 0} new entries
            </Typography>
            <Box className={styles.box_typo_paginehsann}>
              {array.map((item, index) => {
                return (
                  <>
                    <Button
                      className={styles.number_button}
                      sx={{
                        backgroundColor:
                          selectedPage == item + 1 ? "#088164 !important" : "",
                        color: selectedPage == item + 1 ? "#fff !important" : "",
                      }}
                      onClick={() => {
                        const totalNumber = totalUsers;
                        const pageSize = PAGE_LIMIT;
                        const numberOfParts = Math.ceil(totalNumber / pageSize);
                        const ranges = [...Array(numberOfParts).keys()].map(
                          (index) => {
                            const start = index * pageSize + 1;
                            const end = Math.min(
                              (index + 1) * pageSize,
                              totalNumber
                            );
                            const obj = {
                              start: start,
                              end: end,
                            };
                            return obj;
                          }
                        );
  
                        setStartNumber(ranges[item].start);
                        setEndNumber(ranges[item].end);
                        setSelectedPage(item + 1);
                      }}
                    >
                      {item + 1}
                    </Button>
                  </>
                );
              })}
              <IconButton
                disabled={selectedPage == 1 ? true : false}
                onClick={() => {
                  if (selectedPage > 0) {
                    setSelectedPage(selectedPage - 1);
                    const totalCount = totalUsers;
                    const groupSize = PAGE_LIMIT;
                    setStartNumber(Math.max(startNumber - PAGE_LIMIT, 1));
                    const lastGroupSize = totalCount % groupSize;
                    if (endNumber == totalCount) {
                      setEndNumber(endNumber - lastGroupSize);
                    } else {
                      setEndNumber(
                        Math.max(
                          endNumber - PAGE_LIMIT,
                          PAGE_LIMIT > totalUsers ? totalUsers : PAGE_LIMIT
                        )
                      );
                    }
                  }
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton
                disabled={totalPages == selectedPage ? true : false}
                onClick={() => {
                  if (totalPages >= selectedPage) {
                    setSelectedPage(selectedPage + 1);
                    const totalCount = totalUsers;
                    const groupSize = PAGE_LIMIT;
                    const lastGroupSize = totalCount % groupSize;
                    setStartNumber(
                      Math.min(
                        startNumber + PAGE_LIMIT,
                        totalUsers - lastGroupSize + 1
                      )
                    );
                    setEndNumber(Math.min(endNumber + PAGE_LIMIT, totalUsers));
                  }
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Box>
</Box>
      </>
    );
  };
  export default Advertisement;
  