import { Grid } from "@mui/material";
import Sidebar from "../sidebar/sidebar";
import Headar from "../headar/headar";
import User from "../user/user";
const User_layout = ({props,setIsLoaded}) => {
    return (
        <>
            <Grid container>
                <Grid item md={2.3}>
                    <Sidebar />
                </Grid>
                <Grid item md={9.7}>
                    <Headar />
                    <User props={props} setIsLoaded={setIsLoaded} />
                </Grid>
            </Grid>
        </>
    )
}
export default User_layout;