import { Types } from '../constants/actionTypes';
import configureStore from '../store/configureStore';
// import Router from 'next/router';
import { toast } from 'react-toastify';

const store = configureStore()
const GetApiCall = async (
  url,
  header,
) => {
  const rawResponse = await fetch(url, {
    method: 'GET',
    headers: header,
  })
    .then((response) => {
      if (response.status == 401) {
        store.dispatch({ type: Types.LOGIN, payload: { user: '' } });
        toast.error("Your session is expired please login again.");
      } else {
        return response.json()
      }
    })
    .catch((error) => {
      console.error('Error on the GET request', error?.message)
      return null;
    });
  return rawResponse;
};

const PostApiCall = async (
  url,
  payLoad,
  header,
) => {
  if (!!payLoad && Object.keys(payLoad).length > 0) {
    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: header,
      body: payLoad,
    })
      .then((response) => {
        if (response.status == 401) {
          store.dispatch({ type: Types.LOGIN, payload: { user: '' } });
          toast.error("Your session is expired please login again.");
        } else {
          return response.json()
        }
      })
      .catch((error) => {
        console.error('Error on the POST request', error?.message)
        return null;
      });

    return rawResponse;
  } else {
    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: header,
    })
      .then((response) => {
        if (response.status == 401) {
          store.dispatch({ type: Types.LOGIN, payload: { user: '' } });
          toast.error("Your session is expired please login again.");
        } else {
          return response.json()
        }
      })
      .catch((error) => {
        console.error('Error on the GET request', error?.message)
        return null;
      });


    return rawResponse;
  }
};

const apiCalls = {
  GetApiCall,
  PostApiCall,
};

export default apiCalls;