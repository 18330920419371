import Grid from "@mui/material/Grid";
import styles from "./login.module.scss";
import { Box, Typography, TextField, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";
import ApiServices from "../../config/ApiServices";
import ApiEndpoint from "../../config/ApiEndpoint";
import { toast } from "react-toastify";
import { Types } from "../../constants/actionTypes";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField_Err from "../inputerr/textfild_err";
import {InputContentBox } from "../Component/box";
import { TextButton } from "../Component/buttons";
import { Constants } from "../../Utils/constants";
import { useNavigate, useSearchParams } from 'react-router-dom';



const Login = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onLoginPress = async () => {
    var body = {
      email: formik.values.email,
      password: formik.values.password,
    };
    var headers = {
      "Content-Type": "application/json",
    };
    // props.props.loaderRef(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.LOGIN_USER,
      JSON.stringify(body),
      headers
    );
    // props.props.loaderRef(false);
    if (!!data) {
      if (data.status == true) {
        data.token = data.token;
        props.save_user_data({ user: data });
        toast.success("Logged In Succesfully");
        window.location.href = "/user";
      } else {
        toast.error(data.message);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        // .min(6)
        .max(255)
        .required("Password is required"),
    }),
    onSubmit: () => {
      onLoginPress();
    },
  });

  const onSendOtp = async () => {
    var body = {
      email: formik.values.email,
    };
    var headers = {
      "Content-Type": "application/json",
    };
    // props.props.loader(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.FORGOT_PASSWORD,
      JSON.stringify(body),
      headers
    );
    // props.props.loader(false);
    if (data) {
      console.log("data",data);
      if (data.status == true) {
        toast.success(data.message);

      console.log('Email:', formik.values.email);
      console.log('Flow:', Constants.Keys.forgetPass);
      console.log('ID:', data.data.id);

      const queryString = new URLSearchParams({
        email: formik.values.email,
        flow: Constants.Keys.forgetPass,
        id: data.data.id
      }).toString();

      navigate(`/verification?${queryString}`);
      
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("Unauthorised");
    }
  };

  return (
    <>
      <Grid container className={styles.contenar_login}>
        <Grid item md={6} className={styles.grid_item_jjjss}>
          <Box className={styles.box_imgsffsf}>
            <img src="/loginBG.png" width={"100%"} height={621} />
          </Box>
        </Grid>
        <Grid item md={6} className={styles.grid_two_djjdhdhhd}>
          <Box className={styles.vbox_img_jjjaaaa}>
              <Umphire_logo />
          </Box>
          <Box className={styles.box_typo_adminn}>
            <Typography className={styles.typo_hidungafafa}>
              Admin Login
            </Typography>
            <Typography className={styles.well_come_typo}>
              Welcome back!
            </Typography>
            <Divider className={styles.diveydarrafafa} />
            <form onSubmit={formik.handleSubmit}>
              <TextField
                // error={Boolean(formik.touched.email && formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                className={styles.text_fieldd_login}
                id="filled-password-input"
                label="Email Address*"
                type="text"
                autoCapitalize="off"
                // autoComplete="current-password"
                variant="filled"
                // onChange={(e) => { setListmenu(e.target.value) }}
              />
              <TextField_Err
                errors={formik.errors.email}
                touched={formik.touched.email}
              />

              <TextField
                className={styles.text_fieldd_login}
                id="filled-password-input"
                label="Password*"
                type="password"
                autoComplete="current-password"
                // error={Boolean(formik.touched.password && formik.errors.password)}
                // helperText={formik.touched.password && formik.errors.password}
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                variant="filled"
                // onChange={(e) => { setListmenu_two(e.target.value) }}
              />
              <TextField_Err
                errors={formik.errors.password}
                touched={formik.touched.password}
              />
              {/* <Box className={styles.error_text_view}>
                                {formik.errors.password && formik.touched.password && (
                                    <Typography>{formik.errors.password}</Typography>
                                )}
                            </Box> */}
              <Box className={styles._main_box_sjjssss}>
              <InputContentBox
                sx={{
                  alignItems: "flex-end",
                }}
                onClick={() => {
                  if (!(formik.touched.email && formik.errors.email)) {
                    if (formik.values.email) {
                      onSendOtp();
                    }
                  }
                }}
              >
                <TextButton>Forgot Password?</TextButton>
              </InputContentBox>

              </Box>
              <Box className={styles._bottom_btn_loginnna}>
                <Button
                  type="submit"
                  // onClick={()=>{window.location.href = '/dashboard'}}
                >
                  Login
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};


export const Umphire_logo = () => {
  return (
    <svg
      width="200"
      height="36"
      viewBox="0 0 200 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Umphire Logo" clip-path="url(#clip0_10828_610)">
        <path
          id="Vector"
          d="M84.6736 0.537409C84.5544 0.407501 84.4108 0.302817 84.251 0.229468C84.0913 0.156119 83.9187 0.115569 83.7432 0.110183C80.1338 -0.0130787 76.6157 1.26874 73.9182 3.68994C71.4994 5.85425 69.9438 8.83168 69.5415 12.067C69.5415 12.0848 69.5415 12.1027 69.5415 12.1187L69.4426 32.0361C69.4426 32.1266 69.4698 32.215 69.5205 32.2896C69.5712 32.3643 69.6432 32.4217 69.7269 32.4544L76.3793 35C76.5321 35.0587 76.6942 35.0889 76.8578 35.089C77.1224 35.0922 77.3818 35.0149 77.6023 34.8672C77.8227 34.7196 77.9939 34.5084 78.0936 34.2612C78.1582 34.1027 78.1903 33.9327 78.1878 33.7613C78.1854 33.59 78.1485 33.4209 78.0795 33.2644C78.0105 33.1016 77.9095 32.9547 77.7827 32.8323C77.6559 32.7099 77.5058 32.6147 77.3415 32.5523L72.0909 30.5497L72.1103 13.0514C72.1946 11.6367 72.5551 10.2526 73.1714 8.97876C73.7877 7.7049 74.6474 6.56634 75.7013 5.62846C77.8866 3.66528 80.7388 2.62784 83.6638 2.73225H83.6938C84.035 2.73497 84.3641 2.6046 84.6121 2.3684C84.8602 2.1322 85.0081 1.80844 85.025 1.46483C85.0299 1.29452 85.0014 1.12491 84.9411 0.965744C84.8809 0.806579 84.79 0.661014 84.6736 0.537409Z"
          fill="#088164"
        />
        <path
          id="Vector_2"
          d="M102.12 4.17416C101.031 2.9849 99.7156 2.02843 98.2525 1.36153C96.7894 0.694616 95.2084 0.330858 93.6032 0.291841C91.998 0.252824 90.4013 0.539335 88.9081 1.13439C87.4148 1.72944 86.0553 2.62089 84.9103 3.75584C82.5501 6.06891 81.1358 9.1901 80.9467 12.5032C80.9282 12.5533 80.9186 12.6064 80.9185 12.6599V35.445C80.9183 35.5097 80.9321 35.5736 80.959 35.6323C80.9858 35.691 81.025 35.7431 81.0738 35.785C81.1541 35.8526 81.2553 35.8897 81.3598 35.89C81.3839 35.8919 81.4081 35.8919 81.4322 35.89L89.8555 34.466C89.9585 34.4483 90.0521 34.3945 90.1196 34.3141C90.1871 34.2336 90.2242 34.1317 90.2244 34.0263V26.9896C90.2228 26.8758 90.2464 26.763 90.2934 26.6596C90.3404 26.5561 90.4098 26.4645 90.4963 26.3915C90.574 26.3253 90.6653 26.2775 90.7637 26.2516C90.8621 26.2257 90.965 26.2224 91.0648 26.2419C91.8084 26.3865 92.5639 26.4592 93.3211 26.4591C94.9402 26.4564 96.5422 26.125 98.0316 25.4846C99.5209 24.8441 100.867 23.9079 101.99 22.7316C104.363 20.2437 105.7 16.9328 105.724 13.4809C105.748 10.0291 104.459 6.69931 102.12 4.17772V4.17416ZM94.1597 18.2671C93.2868 18.2671 92.4334 18.0061 91.7076 17.5171C90.9818 17.0281 90.416 16.3331 90.082 15.5199C89.7479 14.7068 89.6605 13.812 89.8308 12.9487C90.0011 12.0855 90.4215 11.2925 91.0387 10.6701C91.656 10.0478 92.4425 9.62391 93.2987 9.45219C94.1549 9.28048 95.0423 9.36861 95.8488 9.70544C96.6553 10.0423 97.3446 10.6127 97.8296 11.3445C98.3146 12.0763 98.5735 12.9367 98.5735 13.8169C98.5735 14.9972 98.1085 16.1291 97.2808 16.9637C96.453 17.7983 95.3304 18.2671 94.1597 18.2671Z"
          fill="#088164"
        />
        <path
          id="Vector_3"
          d="M78.8476 18.0731L74.3808 16.4318C74.3034 16.4038 74.2209 16.393 74.1389 16.4C74.057 16.4071 73.9775 16.4319 73.9059 16.4728C73.8391 16.5087 73.7829 16.562 73.7432 16.627C73.7035 16.6921 73.6817 16.7666 73.6799 16.843V19.4758C73.6837 19.568 73.7155 19.6567 73.7711 19.73C73.8267 19.8033 73.9034 19.8575 73.9907 19.8852L78.4592 21.5264C78.5214 21.5492 78.5871 21.5606 78.6533 21.5603C78.7518 21.5608 78.8487 21.535 78.9341 21.4855C78.9996 21.4486 79.0544 21.395 79.0928 21.33C79.1312 21.265 79.152 21.1909 79.153 21.1152V18.4825C79.1496 18.391 79.1185 18.3027 79.0639 18.2295C79.0093 18.1563 78.9338 18.1017 78.8476 18.0731Z"
          fill="#088164"
        />
        <path
          id="Vector_4"
          d="M7.98538 4.67773V21.5619C7.98538 23.1319 8.34674 24.343 9.06942 25.1951C9.7921 26.0471 10.8908 26.4738 12.3656 26.4749C13.8392 26.4749 14.9521 26.0418 15.7042 25.1755C16.4563 24.3092 16.8312 23.1046 16.8288 21.5619V4.67773H24.8548V21.5619C24.8548 24.232 24.2951 26.51 23.1758 28.3957C22.1037 30.2366 20.5151 31.7163 18.6102 32.6483C16.6118 33.6141 14.4191 34.1014 12.2032 34.0724C9.84919 34.0724 7.7541 33.5977 5.91798 32.6483C4.0831 31.7066 2.57077 30.2314 1.57663 28.4135C0.52674 26.5432 0.001177 24.2587 0 21.5601V4.67773H7.98538Z"
          fill="#088164"
        />
        <path
          id="Vector_5"
          d="M62.9756 4.67773V33.7822H54.9495V17.7329L49.5048 33.7911H42.7958L37.3104 17.5994V33.7822H29.2773V4.67773H38.9876L46.235 23.5859L53.3182 4.67773H62.9756Z"
          fill="#088164"
        />
        <path
          id="Vector_6"
          d="M137.272 4.49976V33.6042H129.246V21.9215H119.46V33.6042H111.435V4.49976H119.46V15.4402H129.246V4.49976H137.272Z"
          fill="#0A0A0A"
        />
        <path
          id="Vector_7"
          d="M149.883 4.49976V33.6042H141.859V4.49976H149.883Z"
          fill="#0A0A0A"
        />
        <path
          id="Vector_8"
          d="M169.086 33.6042L163.353 22.8703H162.494V33.6042H154.469V4.49976H167.121C169.441 4.49976 171.407 4.90562 173.018 5.71734C174.629 6.52906 175.844 7.64399 176.662 9.06213C177.492 10.5101 177.919 12.157 177.898 13.8292C177.932 15.7005 177.365 17.5327 176.281 19.052C175.201 20.5532 173.625 21.6213 171.551 22.2562L178.06 33.6096L169.086 33.6042ZM162.494 17.3395H166.466C167.557 17.3395 168.376 17.0779 168.924 16.5545C169.471 16.0312 169.743 15.2741 169.741 14.2831C169.741 13.3492 169.462 12.6134 168.902 12.0758C168.343 11.5382 167.531 11.2694 166.466 11.2694H162.494V17.3395Z"
          fill="#0A0A0A"
        />
        <path
          id="Vector_9"
          d="M189.559 10.9811V15.7286H198.773V21.8806H189.559V27.1229H200V33.6042H181.535V4.49976H200V10.9811H189.559Z"
          fill="#0A0A0A"
        />
      </g>
      <defs>
        <clipPath id="clip0_10828_610">
          <rect
            width="200"
            height="35.7798"
            fill="white"
            transform="translate(0 0.110107)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
  save_user_data: (data) => dispatch({ type: Types.LOGIN, payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
