import {Box,Typography} from '@mui/material'
import styles from './textfilds_err.module.scss'
const TextField_Err = ({ errors, touched }) => {
    return (
        <>
            <Box className={styles.error_text_view}>
                {errors && touched && (
                    <Box className={styles.error_text_box}>
                        <Typography className={styles.error_text}>{errors}</Typography>
                    </Box>
                )}
            </Box>
        </>
    )
}
export default TextField_Err;