import Grid from '@mui/material/Grid';
import styles from './login.module.scss';
import { Box, Typography, TextField, Button } from '@mui/material'
import Divider from '@mui/material/Divider';

import React from 'react';
import ApiServices from '../../config/ApiServices';
import ApiEndpoint from '../../config/ApiEndpoint'
import { toast } from "react-toastify";
import { Types } from "../../constants/actionTypes";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField_Err from '../inputerr/textfild_err';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

const ChangePassword = (props) => {
    const [token, setToken] = useState("");
    const location = useLocation()

    useEffect(() => {
        getRequestToken();
      }, []);

      const getRequestToken = () => {
        const useQuery = new URLSearchParams(location.search);
        let request_token = useQuery.get('token');
        if (!!request_token) {
          setToken(request_token);
        }
      };

    const createPassword = async () => {
        var headers = {
          "Content-Type": "application/json",
          "x-access-token": token,
        };
        var body = {
          password: formik.values.password,
        };
        // props.loaderRef(true);
        var data = await ApiServices.PostApiCall(
          ApiEndpoint.CREATE_PASSWORD,
          JSON.stringify(body),
          headers
        );
        // props.loaderRef(false);
        if (!!data) {
          if (data.status == true) {
            toast.success(data.message);
            window.location.href = "/"
          } else {
            toast.error(data.message);
          }
        } 
      };
    const formik = useFormik({
        initialValues: {
            newpassword: "",
            password: "",
        },
        validationSchema: Yup.object({
            newpassword: Yup.string()
                .required("New Password* is required"),
                // .newpassword("Email is invalid"),
            password: Yup.string()
                // .min(6)
                .max(255)
                .oneOf([Yup.ref('newpassword')], 'Passwords does not match')
                .required("Confirm Password* is required"),
        }),
        onSubmit: () => {
             createPassword();
        },
    });
    return (
        <>
            <Grid container className={styles.contenar_login}>
                <Grid item md={6} className={styles.grid_item_jjjss}>
                    <Box className={styles.box_imgsffsf}>
                        <img src='/Rectangle 4915.jpg' width={'100%'} height={621} />
                    </Box>
                </Grid>
                <Grid item md={6} className={styles.grid_two_djjdhdhhd}>
                    <Box className={styles.vbox_img_jjjaaaa}>
                        <img width={70} src='/XMLID_2187_.svg' />
                    </Box>
                    <Box className={styles.box_typo_adminn}>
                        <Typography className={styles.typo_hidungafafa}>
                        Change Password
                        </Typography>
                        <Typography className={styles.well_come_typo}>
                        Admin sent you a temporary password. You can change it anytime.
                        </Typography>
                        <Divider className={styles.diveydarrafafa} />
                        <form onSubmit={formik.handleSubmit}>
                            <TextField
                                // error={Boolean(formik.touched.email && formik.errors.email)}
                                // helperText={formik.touched.email && formik.errors.email}
                                name="newpassword"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.newpassword}
                                className={styles.text_fieldd_login}
                                id="filled-password-input"
                                label="New Password*"
                                type="password"
                                autoComplete="current-password"
                                variant="filled"
                            // onChange={(e) => { setListmenu(e.target.value) }}
                            />
                            <TextField_Err errors={formik.errors.newpassword} touched={formik.touched.newpassword} />

                            <TextField
                                className={styles.text_fieldd_login}
                                id="filled-password-input"
                                label="Confirm Password*"
                                type="password"
                                placeholder='Confirm Password'
                                autoComplete="current-password"
                                name="password"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                variant="filled"
                            // onChange={(e) => { setListmenu_two(e.target.value) }}
                            />
                            <TextField_Err errors={formik.errors.password} touched={formik.touched.password} />

                            <Box className={styles._bottom_btn_loginnna}>
                                <Button type='submit'>
                                Set Password
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </>
    )

}
const mapStateToProps = (state) => ({
    profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
    save_user_data: (data) => dispatch({ type: Types.LOGIN, payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
