import { Box, TextField, Typography, styled } from "@mui/material";
import React from "react";

export const Input_Box = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "column",
  padding: "11px 21px",
  borderRadius: "10px",
  border: "1.2px solid #EFEFEF",
  background: "#FAFAFA",
}));

export const Input_field = styled(TextField)(({}) => ({
  "& .MuiInputBase-input": {
    padding: "0px",
    border: "0px",
    outline: "0px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "0px !important",
    outline: "0px !important",
  },
  "& input::placeholder": {
    color: "#AAA",
    fontFamily: "Poppins-400",
    fontSize: "14px",
  },
}));

export const Input_lable = styled(Typography)(({}) => ({
  color: "#FF7606",
  fontFamily: "Mulish-400",
  fontSize: "14px",
}));

export const Input = styled(TextField)(({}) => ({
  // width: "auto",
  "& input": {
    color: "#0A0A0A",
    fontFamily: "Roboto",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "22px" /* 129.412% */,
    padding: "0px",
  },
  "& fieldset": {
    border: "0px !important",
  },
}));
