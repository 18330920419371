import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import Styles from "./notify.module.scss";
import { Calender_icon, CheckedIcon, UnCheckedIcon } from "../Component/icons";
import { Flex_start_box } from "../Component/box";
import { Input_Box, Input_field, Input_lable } from "../Component/input";
import Editor from "react-simple-wysiwyg";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const Notifications = () => {
  const [allUser, setAllUser] = React.useState(false);
  const [selectedUser, setSelecteduser] = React.useState(false);
  const [html, setHtml] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const dataArray = [
    { date: "2023-08-12T00:00:00Z", title: "Event A" },
    { date: "2023-08-15T08:00:00Z", title: "Event B" },
    { date: "2023-08-18T12:00:00Z", title: "Event C" },
    { date: "2023-08-22T15:30:00Z", title: "Event D" },
    { date: "2023-08-25T18:45:00Z", title: "Event E" },
  ];

  function onChange(e) {
    setHtml(e.target.value);
  }
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <IconButton
      sx={{
        backgroundColor: "#F1F3F6",
        borderRadius: "13px",
        padding: "0px",
      }}
      ref={ref}
      onClick={onClick}
    >
      <Calender_icon />
    </IconButton>
  ));

  return (
    <Box className={Styles.main_box}>
      <Grid
        container
        sx={{
          height: "100%",
        }}
      >
        <Grid item md={8} className={Styles.left_grid}>
          <Box className={Styles.top_box}>
            <Box className={Styles.top_row_box}>
              <Typography className={Styles.title_text}>
                Push Notifications
              </Typography>
              <Box className={Styles.date_picker_box}>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  customInput={<ExampleCustomInput />}
                />
              </Box>
            </Box>
            <Flex_start_box>
              <FormControlLabel
                label="All users"
                control={
                  <Checkbox
                    icon={<UnCheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                    checked={allUser}
                    onChange={(event) => {
                      setAllUser(event.target.checked);
                    }}
                  />
                }
              />
              <FormControlLabel
                label="Selected Taskers"
                control={
                  <Checkbox
                    icon={<UnCheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                    checked={selectedUser}
                    onChange={(event) => {
                      setSelecteduser(event.target.checked);
                    }}
                  />
                }
              />
            </Flex_start_box>
            <Box className={Styles.inside_box}>
              <Input_Box>
                <Input_lable>Title</Input_lable>
                <Input_field placeholder="Title here" />
              </Input_Box>
              <Input_Box>
                <Input_lable>Title</Input_lable>
                <Input_field placeholder="Title here" />
              </Input_Box>
            </Box>
          </Box>
          <Box className={Styles.bottom_box}>
            <Editor
              value={html}
              onChange={onChange}
              style={{
                width: "100%",
                height: "500px",
              }}
            />
          </Box>
        </Grid>
        <Grid item md={4} className={Styles.right_grid}>
          <Box className={Styles.list_box}>
            <Typography className={Styles.header_history}>History</Typography>
            {dataArray.map((item, index) => {
              return (
                <>
                  <Box className={Styles.list_row_box}>
                    <Typography className={Styles.date_text}>
                      {moment(item.date).format("DD/MM/YYYY")}
                    </Typography>
                    <Typography className={Styles.title_text_list}>
                      {item.title}
                    </Typography>
                  </Box>
                </>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
