import { Button } from "@mui/material";
import { styled } from "@mui/material";
import React from "react";

export const PrimaryButton = styled(Button)(({}) => ({
  // Create Account
  color: "#0A0A0A",
  fontSize: "17px",
  fontFamily: "Roboto",
  fontWeight: "600",
  lineHeight: "normal",
  wordWrap: "break-word",
  background: "#B0EB79 !important",
  padding: "16px",
  textTransform: "capitalize",
}));

export const TextButton = styled(Button)(({ clr, bg }) => ({
  background: bg || "transparent",
  color: clr || "#3B7B00",
  fontSize: "15px",
  fontFamily: "Roboto",
  fontWeight: "600",
  lineHeight: "normal",
  wordWrap: "break-word",
  height: "17px",
  width: "fit-content",
  padding: "0px",
  textTransform:"capitalize"
}));
