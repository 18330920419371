import { Box, styled } from "@mui/material";
import React from "react";

export const Flex_start_box = styled(Box)(({}) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

export const InputContentBox = styled(Box)(
  ({ color, fontSize, fontFamily }) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: "3px",
  })
);

export const ColumnBox = styled(Box)(({ color, fontSize, fontFamily, rg }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  rowGap: rg || "",
  width: "100%",
}));

export const Input_Box = styled(Box)(
  ({ color, fontSize, fontFamily, height }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "5px",
    borderRadius: "4px",
    border: "1px solid #E5E5E5",
    background: "#F3F3F5",
    padding: "8px 12px",
  })
);