import React, { useCallback, useMemo, useState } from "react";
import styles from "./Guidelines.module.scss";
import {
  Box,
  Tab,
  Tabs,
  Grid,
  Typography,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Divider,
} from "@mui/material";
import Editor from "react-simple-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import { Types } from "../../constants/actionTypes";
import ApiServices from "../../config/ApiServices";
import ApiEndpoint from "../../config/ApiEndpoint";
import { toast } from "react-toastify";

// menu onclick tab start func

function TabPanel(props) {
  const { children, value, index } = props;
  // const { currentIndex } = props
  return <div>{value === index && children}</div>;
}

const Guidelines = (props) => {
  const [value, setValue] = React.useState(0);
  const [currentIndex, setCurrentIndex] = useState(3);
  const [Index, setIndex] = useState(3);
  const [showHomeImprovement, setShowHomeImprovement] = useState(false);
  const [showEnviromental, setShowEnviromental] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [usertype, setUsertype] = useState("all");
  const [newSubCategory, setNewSubCategory] = useState("");
  const [open, setOpen] = useState(false);
  const [popupInput, setPopupInput] = useState([]);
  const [newPopupInput, setNewPopupInput] = useState("");
  const [term, setTerm] = useState("terms_conditions");
  const [html, setHtml] = useState("");
  const [privacy, setPrivacy] = useState("privacy");
  const [htmltwo, setHtmltwo] = useState("");
  const [faq, setFaq] = useState("faq");
  const [htmlthree, setHtmlthree] = useState("");
  const [notification, setNotification] = useState("");
  const [sportsList, setSportsList] = useState([]);
  const [chosenSport, setChosenSport] = useState();
  const [sportlink, setSportlink] = useState();

  const sempleHtml = `<div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;"><div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;"><div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;"><div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;"><div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;"><div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;"><div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;"><p><br></p></div></div></div></div></div></div></div>`;

  const sempleHtmlTwo = `<div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;"><div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:15px;"><p><br></p></div></div>`;

  const sempleHtmlThree = `<div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;"><div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px;"><div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px;"><div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px;"><p><br></p></div></div></div></div>`;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Guidelines = useCallback( async () => {
    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.profile.token,
    };
    // Fetch data for terms & condition
    var termsData = await ApiServices.GetApiCall(
      `${ApiEndpoint.GUIDELINES_PAGE}${term}?user_type=${usertype}`,
      headers
    );

    if (!!termsData) {
      if (termsData.status === true) {
        setHtml(termsData.data.description);
      } 
    }

    // Fetch data for privacy policy
    var privacyData = await ApiServices.GetApiCall(
      `${ApiEndpoint.GUIDELINES_PAGE}${privacy}?user_type=all`,
      headers
    );

    if (!!privacyData) {
      if (privacyData.status === true) {
        const htmlWithStyles = `<div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;">${privacyData.data.description.replace(
          /privacy/gi,
          '<span style="color: #F86624;">Privacy</span>'
        )}</div>`;

        setHtmltwo(htmlWithStyles);
      } else {
        // toast.error(privacyData.message);
      }
    }

    // Fetch data for FAQ
    var faqData = await ApiServices.GetApiCall(
      `${ApiEndpoint.GUIDELINES_PAGE}${faq}?user_type=all`,
      headers
    );

    if (!!faqData) {
      if (faqData.status === true) {
        const htmlWithStyle = `<div style="font-family: 'Poppins', sans-serif; color: #373737; font-weight: 600; font-size: 14px; line-height: 21px; margin-top:16px;">${faqData.data.description.replace(
          /faq/gi,
          '<span style="color: #F86624;">FAQ</span>'
        )}</div>`;
        setHtmlthree(htmlWithStyle);
      } else {
        // toast.error(faqData.message);
      }
    }

    var sportlink = await ApiServices.GetApiCall(
      `${ApiEndpoint.GUIDELINES_PAGE}${"sportlink"}?user_type=${"all"}`,
      headers
    );

    var sportsData = await ApiServices.PostApiCall(
      `${ApiEndpoint.SPORT_LIST_COMMON}`,{},
      headers
    );

    if (sportsData && sportsData.status && sportsData.data) {
      setSportsList(sportsData.data);
      setChosenSport(sportsData.data[0].id);
    }

    if (!!sportlink) {
      if (sportlink.status) {
        if (sportlink.data.description) {
          setSportlink([...JSON.parse(sportlink.data.description)]);
        }

      } else {
        if (sportsData?.data) {
        setSportlink([...sportsData.data?.map((sport) => (
          {
            id: sport.id, 
            data:
              [
                {
                  id: 1,
                  name: '',
                  desc: '',
                  link: "",
                  image: "",
                  logoImage: "",
                },
                {
                  id: 2,
                  name: '',
                  desc: '',
                  link: "",
                  image: "",
                  logoImage: "",
                },
                {
                  id: 3,
                  name: '',
                  desc: '',
                  link: "",
                  image: "",
                  logoImage: "",
                },
              ]
            }))])


          }
    
        // toast.error(termsData.message);
      }
    }

    var notification = await ApiServices.GetApiCall(
      `${ApiEndpoint.GUIDELINES_PAGE}${"notification"}?user_type=${"all"}`,
      headers
    );

    if (!!notification) {
      if (notification.status === true) {
        if (notification.data.description) {
          setNotification(notification.data.description);
        }

      } 
    }
  },[faq, privacy, props.profile.token, sportsList, term, usertype]);

  React.useEffect(() => {
    if (props && props.profile && props.profile.token) {
      Guidelines();
    }
  }, [props.profile.token, usertype]);

  const saveFAQ = async () => {
    var description;
    var type;
    if (value === 0) {
      description = html;
      type = "terms_conditions";
    } else if (value === 1) {
      description = htmltwo;
      type = "privacy";
    } else if (value === 2) {
      description = htmlthree;
      type = "faq";
    }

    const body = {
      description: description,
      type: type,
      user_type: type === "privacy" || type === "faq" ? "all" : usertype,
    };

    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.profile.token,
    };

    var data = await ApiServices.PostApiCall(
      ApiEndpoint.UPDATE_ADD,
      JSON.stringify(body),
      headers
    );

    if (!!data) {
      if (data.status === true) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    }
  };

  // terms & condition editor

  function onChange(e) {
    setHtml(e.target.value);
  }

  // privacy policy editor

  function onChange_two(e) {
    setHtmltwo(e.target.value);
  }

  // FAQ editor

  function onChange_three(e) {
    setHtmlthree(e.target.value);
  }

  // showHomeImprovement condition

  const handleChange_two = (event, newValue) => {
    setCurrentIndex(newValue);
    if (newValue === 3) {
      setCurrentIndex(3); // Show interests tab
    } else {
      setCurrentIndex(0); // Hide interests tab
    }

    if (newValue === 3 || newValue === 0) {
      setShowHomeImprovement(true);
    } else {
      setShowHomeImprovement(false);
    }
  };

  // ShowEnviromental condition

  const handleChange_three = (event, newValue) => {
    setIndex(newValue);
    if (newValue === 4) {
      setIndex(4); // Show interests tab
    } else {
      setIndex(0); // Hide interests tab
    }

    if (newValue === 3 || newValue === 0) {
      setShowEnviromental(true);
    } else {
      setShowEnviromental(false);
    }
  };

  // add sub category condition

  const handleAddSubCategory = () => {
    if (newSubCategory) {
      setSubCategories([...subCategories, newSubCategory]);
      setNewSubCategory("");
    }
  };

  // remove sub category condition

  const handleRemoveSubCategory = (index) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories.splice(index, 1);
    setSubCategories(updatedSubCategories);
  };

  // Add button popup add & remove

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddButtonClick = () => {
    if (newPopupInput) {
      setPopupInput([...popupInput, newPopupInput]);
      setNewPopupInput("");
    }
  };

  const handleRemovePopupInput = (index) => {
    const updatedPopupInput = [...popupInput];
    updatedPopupInput.splice(index, 1);
    setPopupInput(updatedPopupInput);
  };

  // Volunteer

  const handleClickOpen_two = () => {
    setOpen(true);
  };

  const handleAddSubCategory_two = () => {
    if (newSubCategory) {
      setSubCategories([...subCategories, newSubCategory]);
      setNewSubCategory("");
    }
  };

  const handleRemoveSubCategory_two = (index) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories.splice(index, 1);
    setSubCategories(updatedSubCategories);
  };

  const handleRemovePopupInput_two = (index) => {
    const updatedPopupInput = [...popupInput];
    updatedPopupInput.splice(index, 1);
    setPopupInput(updatedPopupInput);
  };

  const updateState = (item, index) => {

    const copySportLink = [...sportlink]

    const sportItemIndex = copySportLink.findIndex((sport) => sport.id === chosenSport);

    const newItem = {...copySportLink[sportItemIndex], data: copySportLink[sportItemIndex].data.map((x, i) => {
      return i === index
        ? { ...copySportLink?.[sportItemIndex].data[index], ...item }
        : x;
    })}

    copySportLink.splice(sportItemIndex, 1, newItem)

    setSportlink(copySportLink);
  }

  const chosenSportLink = useMemo(() => {

    const linkdata = sportlink?.find(item => item.id === chosenSport)?.data

    return linkdata || []
  }, [chosenSport, sportlink])

  return (
    <>
      <Grid container className={styles.container_grid_md}>
        <Grid item md={4}>
          <Box className={styles.box_main_guidelines}>
            <Typography className={styles.heading_typo}>Guidelines</Typography>
            <Typography className={styles.heading_typo2}>
              you can manage
            </Typography>
            <Box className={styles.add_input_text}>
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "#FFFFFF",
                  display: "flex",
                  height: 324,
                }}
              >
                <Tabs
                  orientation='vertical'
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{ style: { display: "none" } }}
                >
                  <Tab
                    className={styles.tearm_conditions}
                    label='Terms & Conditions'
                    // {...menuProps(0)}
                    onClick={() => {
                      setTerm("terms_conditions");
                    }}
                    sx={{
                      "&.Mui-selected": {
                        color: "#1C1C1C",
                        border: "1px solid #EFEFEF",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 30px rgba (221, 221, 221, 0.25)",
                      },
                    }}
                  />
                  <Tab
                    className={styles.tearm_conditions}
                    label='Privacy Policy'
                    // {...menuProps(1)}
                    onClick={() => {
                      setPrivacy("privacy");
                    }}
                    sx={{
                      "&.Mui-selected": {
                        color: "#1C1C1C",
                        border: "1px solid #EFEFEF",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 30px rgba (221, 221, 221, 0.25)",
                      },
                    }}
                  />
                  <Tab
                    className={styles.tearm_conditions}
                    label='Sport Link'
                    // {...menuProps(2)}
                    onClick={() => {
                      // setFaq("faq");
                    }}
                    sx={{
                      "&.Mui-selected": {
                        color: "#1C1C1C",
                        border: "1px solid #EFEFEF",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 30px rgba    (221, 221, 221, 0.25)",
                      },
                    }}
                  />

                  <Tab
                    className={styles.tearm_conditions}
                    label='Notification'
                    // {...menuProps(2)}
                    onClick={() => {
                      // setFaq("faq");
                    }}
                    sx={{
                      "&.Mui-selected": {
                        color: "#1C1C1C",
                        border: "1px solid #EFEFEF",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 30px rgba    (221, 221, 221, 0.25)",
                      },
                    }}
                  />
                </Tabs>

                {/* <Button>

                </Button> */}
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* start tab 1 */}
        <Grid item md={8}>
          <TabPanel value={value} index={0}>
            <Box className={styles.box_main_guidelines_sec}>
              <Box className={styles.box_main_guidelines2}>
                <div className={styles.editor_package}>
                  <FormControl
                    style={{ marginBottom: 10, marginTop: 10 }}
                    fullWidth
                  >
                    <InputLabel id='demo-simple-select-label'>
                      Select Role
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={usertype}
                      label='Select Role'
                      onChange={(e) => {
                        setUsertype(e.target.value);
                      }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      <MenuItem value={"umphires"}>Umpire</MenuItem>
                      <MenuItem value={"schools"}>School</MenuItem>
                      <MenuItem value={"verifier"}>Verifier</MenuItem>
                      <MenuItem value={"individual"}>Individual</MenuItem>
                      <MenuItem value={"coach"}>Coach</MenuItem>
                      <MenuItem value={"strength"}>Strength and Conditioning coach</MenuItem>
                      <MenuItem value={"sports_psychologist"}>Physio or Sports Psychologist</MenuItem>
                    </Select>
                  </FormControl>
                  <Editor
                    value={html}
                    onChange={onChange}
                    style={{
                      height: "700px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  />
                  <Button
                    onClick={saveFAQ}
                    variant='contained'
                    className={styles.saveBtn}
                    disabled={html === sempleHtml ? true : false}
                  >
                    save
                  </Button>
                </div>
              </Box>
            </Box>
          </TabPanel>

          {/* close tab 1 */}

          {/* start tab 2 */}

          <TabPanel value={value} index={1}>
            <Box className={styles.box_main_guidelines_sec}>
              <Box className={styles.box_main_guidelines2}>
                <div className={styles.editor_package}>
                  <Editor
                    value={htmltwo}
                    onChange={onChange_two}
                    style={{
                      height: "700px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  />
                  <Button
                    onClick={saveFAQ}
                    variant='contained'
                    className={styles.saveBtn}
                    disabled={htmltwo === sempleHtmlTwo ? true : false}
                  >
                    save
                  </Button>
                </div>
              </Box>
            </Box>
          </TabPanel>

          {/* close tab 2 */}

          {/* start tab 3 */}
          <TabPanel value={value} index={2}>
            <Box
              style={{ padding: 10 }}
              className={styles.box_main_guidelines_sec}
            >
              <Box className={styles.box_main_guidelines2}>
                <div className={styles.editor_package}>
                <FormControl
                    style={{ marginBottom: 10, marginTop: 10 }}
                    fullWidth
                  >
                    <InputLabel id='demo-simple-select-label'>
                      Select Sport
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      defaultValue={sportsList?.[0]?.id}
                      value={chosenSport}
                      label='Select Sport'
                      onChange={(e) => {
                        setChosenSport(e.target.value);
                      }}
                    >
                    {sportsList?.map((sport, index) => (
                          <MenuItem defaultChecked={index === 0} value={sport?.id}>{sport?.name}</MenuItem>
                        )
                      )
                    }
                    </Select>
                  </FormControl>
                  <Box marginBottom={'10px'} marginTop={'30px'}>
                    <TextField
                      focused
                      value={chosenSportLink?.[0]?.name}
                      onChange={(e) => {
                        updateState({name: e.target.value}, 0)
                      }}
                      label='1st Title'
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <TextField
                      focused
                      value={chosenSportLink?.[0]?.desc}
                      onChange={(e) => {
                        updateState({desc: e.target.value}, 0)
                      }}
                      label='1st Description'
                      fullWidth
                    />
                  </Box>
                  <Box>
                  <InputLabel style={{marginTop: 20}}>
                      1st Logo
                  </InputLabel>
                  <input
                  style={{marginTop: 10}}
                    type='file'
                    onChange={async (event) => {
                      var filename = event.target.files[0];
                      var formData = new FormData();
                      var headers = {
                        "x-access-token": props.profile.token,
                      };
                      if (event.target.files[0]) {
                        formData.append("file", filename);
                        formData.append("type", filename.type);
                        var requestOptions = {
                          method: "POST",
                          body: formData,
                          headers: headers,
                        };

                        const data = await fetch(
                          ApiEndpoint.UPLOAD,
                          requestOptions
                        )
                          .then((response) => response.json())
                          .then((result) => {
                            return result;
                          })
                          .catch((error) => console.error("error", error));
                        if (data) {
                          if (data.status) {
                            updateState({logoImage: data?.data?.url}, 0);
                          } else {
                            toast.error(data.message);
                          }
                        } else {
                          toast.error("Something went wrong!");
                        }
                      }
                      event.target.value = null;
                    }}
                  />
                  {chosenSportLink?.[0]?.logoImage && (
                    <a target='_blank' href={chosenSportLink?.[0]?.logoImage}>
                      View file
                    </a>
                  )}
                  </Box>
                  <Box>
                  <InputLabel style={{marginTop: 20}}>
                      1st Background Image
                  </InputLabel>
                  <input
                    style={{ marginTop: 10 }}
                    type='file'
                    onChange={async (event) => {
                      var filename = event.target.files[0];
                      var formData = new FormData();
                      var headers = {
                        // "Content-Type": "application/json",
                        "x-access-token": props.profile.token,
                      };
                      if (event.target.files[0]) {
                        formData.append("file", filename);
                        formData.append("type", filename.type);
                        var requestOptions = {
                          method: "POST",
                          body: formData,
                          headers: headers,
                        };

                        const data = await fetch(
                          ApiEndpoint.UPLOAD,
                          requestOptions
                        )
                          .then((response) => response.json())
                          .then((result) => {
                            return result;
                          })
                          .catch((error) => console.error("error", error));
                        if (data) {
                          if (data.status) {
                            updateState({image: data?.data?.url}, 0)
                          } else {
                            toast.error(data.message);
                          }
                        } else {
                          toast.error("Something went wrong!");
                        }
                      }
                      event.target.value = null;
                    }}
                  />
                  {chosenSportLink?.[0]?.image && (
                    <a target='_blank' href={chosenSportLink?.[0]?.image}>
                      View file
                    </a>
                  )}
                  </Box>
                  <TextField
                    focused
                    style={{ marginTop: 20 }}
                    label='1st Link'
                    fullWidth
                    value={chosenSportLink?.[0]?.link}
                    onChange={(e) => {
                      updateState({link: e.target.value}, 0)
                    }}
                  />
                  <Divider style={{ height: 2, marginTop: 10 }} />
                  <Box marginBottom={'10px'} marginTop={'30px'}>
                    <TextField
                      focused
                      value={chosenSportLink?.[1]?.name}
                      onChange={(e) => {
                        updateState({name: e.target.value}, 1)
                      }}
                      label='2nd Title'
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <TextField
                      focused
                      value={chosenSportLink?.[1]?.desc}
                      onChange={(e) => {
                        updateState({desc: e.target.value}, 1)
                      }}
                      label='2nd Description'
                      fullWidth
                    />
                  </Box>
                  <Box>
                  <InputLabel style={{marginTop: 20}}>
                      2nd Logo
                  </InputLabel>
                  <input
                  style={{marginTop: 10}}
                    type='file'
                    onChange={async (event) => {
                      var filename = event.target.files[0];
                      var formData = new FormData();
                      var headers = {
                        "x-access-token": props.profile.token,
                      };
                      if (event.target.files[0]) {
                        formData.append("file", filename);
                        formData.append("type", filename.type);
                        var requestOptions = {
                          method: "POST",
                          body: formData,
                          headers: headers,
                        };

                        const data = await fetch(
                          ApiEndpoint.UPLOAD,
                          requestOptions
                        )
                          .then((response) => response.json())
                          .then((result) => {
                            return result;
                          })
                          .catch((error) => console.error("error", error));
                        if (data) {
                          if (data.status) {
                            updateState({logoImage: data?.data?.url}, 1)
                          } else {
                            toast.error(data.message);
                          }
                        } else {
                          toast.error("Something went wrong!");
                        }
                      }
                      event.target.value = null;
                    }}
                  />
                  {chosenSportLink?.[1]?.logoImage && (
                    <a target='_blank' href={chosenSportLink?.[1]?.logoImage}>
                      View file
                    </a>
                  )}
                  </Box>
                  <Box>
                  <InputLabel style={{marginTop: 20}}>
                      2nd Background Image
                  </InputLabel>
                  <input
                    style={{ marginTop: 10 }}
                    type='file'
                    onChange={async (event) => {
                      var filename = event.target.files[0];
                      var formData = new FormData();
                      var headers = {
                        // "Content-Type": "application/json",
                        "x-access-token": props.profile.token,
                      };
                      if (event.target.files[0]) {
                        formData.append("file", filename);
                        formData.append("type", filename.type);
                        var requestOptions = {
                          method: "POST",
                          body: formData,
                          headers: headers,
                        };

                        const data = await fetch(
                          ApiEndpoint.UPLOAD,
                          requestOptions
                        )
                          .then((response) => response.json())
                          .then((result) => {
                            return result;
                          })
                          .catch((error) => console.error("error", error));
                        if (data) {
                          if (data.status) {
                            updateState({image: data?.data?.url}, 1)
                          } else {
                            toast.error(data.message);
                          }
                        } else {
                          toast.error("Something went wrong!");
                        }
                      }
                      event.target.value = null;
                    }}
                  />
                  {chosenSportLink?.[1]?.image && (
                    <a target='_blank' href={chosenSportLink?.[1]?.image}>
                      View file
                    </a>
                  )}
                  </Box>
                  <TextField
                    focused
                    style={{ marginTop: 20 }}
                    label='2nd Link'
                    fullWidth
                    value={chosenSportLink?.[1]?.link}
                    onChange={(e) => {
                      updateState({link: e.target.value}, 1)
                    }}
                  />
                  <Divider style={{ height: 2, marginTop: 10 }} />
                  <Box marginBottom={'10px'} marginTop={'30px'}>
                    <TextField
                      focused
                      value={chosenSportLink?.[2]?.name}
                      onChange={(e) => {
                        updateState({name: e.target.value}, 2)
                      }}
                      label='3rd Title'
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <TextField
                      focused
                      value={chosenSportLink?.[2]?.desc}
                      onChange={(e) => {
                        updateState({desc: e.target.value}, 2)
                      }}
                      label='3rd Description'
                      fullWidth
                    />
                  </Box>
                  <Box>
                  <InputLabel style={{marginTop: 20}}>
                      3rd Logo
                  </InputLabel>
                  <input
                  style={{marginTop: 10}}
                    type='file'
                    onChange={async (event) => {
                      var filename = event.target.files[0];
                      var formData = new FormData();
                      var headers = {
                        "x-access-token": props.profile.token,
                      };
                      if (event.target.files[0]) {
                        formData.append("file", filename);
                        formData.append("type", filename.type);
                        var requestOptions = {
                          method: "POST",
                          body: formData,
                          headers: headers,
                        };

                        const data = await fetch(
                          ApiEndpoint.UPLOAD,
                          requestOptions
                        )
                          .then((response) => response.json())
                          .then((result) => {
                            return result;
                          })
                          .catch((error) => console.error("error", error));
                        if (data) {
                          if (data.status) {
                            updateState({logoImage: data?.data?.url}, 2)
                          } else {
                            toast.error(data.message);
                          }
                        } else {
                          toast.error("Something went wrong!");
                        }
                      }
                      event.target.value = null;
                    }}
                  />
                  {chosenSportLink?.[2]?.logoImage && (
                    <a target='_blank' href={chosenSportLink?.[2]?.logoImage}>
                      View file
                    </a>
                  )}
                  </Box>
                  <Box>
                  <InputLabel style={{marginTop: 20}}>
                      3rd Background Image
                  </InputLabel>
                  <input
                    style={{ marginTop: 10 }}
                    type='file'
                    onChange={async (event) => {
                      var filename = event.target.files[0];
                      var formData = new FormData();
                      var headers = {
                        // "Content-Type": "application/json",
                        "x-access-token": props.profile.token,
                      };
                      if (event.target.files[0]) {
                        formData.append("file", filename);
                        formData.append("type", filename.type);
                        var requestOptions = {
                          method: "POST",
                          body: formData,
                          headers: headers,
                        };

                        const data = await fetch(
                          ApiEndpoint.UPLOAD,
                          requestOptions
                        )
                          .then((response) => response.json())
                          .then((result) => {
                            return result;
                          })
                          .catch((error) => console.error("error", error));
                        if (data) {
                          if (data.status) {
                            updateState({image: data?.data?.url}, 2)
                          } else {
                            toast.error(data.message);
                          }
                        } else {
                          toast.error("Something went wrong!");
                        }
                      }
                      event.target.value = null;
                    }}
                  />
                  {chosenSportLink?.[2]?.image && (
                    <a target='_blank' href={chosenSportLink?.[2]?.image}>
                      View file
                    </a>
                  )}
                  </Box>
                  <TextField
                    focused
                    style={{ marginTop: 20 }}
                    label='3rd Link'
                    fullWidth
                    value={chosenSportLink?.[2]?.link}
                    onChange={(e) => {
                      updateState({link: e.target.value}, 2)
                    }}
                  />
                </div>

                <Button
                  onClick={async () => {
                    const body = {
                      description: JSON.stringify(sportlink),
                      type: "sportlink",
                      user_type: "all",
                      chosen_sport: chosenSport,
                    };

                    var headers = {
                      "Content-Type": "application/json",
                      "x-access-token": props.profile.token,
                    };

                    var data = await ApiServices.PostApiCall(
                      ApiEndpoint.UPDATE_ADD,
                      JSON.stringify(body),
                      headers
                    );

                    if (!!data) {
                      if (data.status === true) {
                        toast.success(data.message);
                      } else {
                        toast.error(data.message);
                      }
                    }
                  }}
                  variant='contained'
                  className={styles.saveBtn}
                  // disabled={html === sempleHtml ? true : false}
                >
                  save
                </Button>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value={value} index={3}>
            <Box
              style={{ padding: 10 }}
              className={styles.box_main_guidelines_sec}
            >
              <Box className={styles.box_main_guidelines2}>
                <div className={styles.editor_package}>
                  <TextField
                    value={notification}
                    onChange={(e) => {
                      setNotification(e.target.value);
                    }}
                    label='Notification'
                    fullWidth
                  />
                </div>

                <Button
                  onClick={async () => {
                    const body = {
                      description: notification,
                      type: "notification",
                      user_type: "all",
                    };

                    var headers = {
                      "Content-Type": "application/json",
                      "x-access-token": props.profile.token,
                    };

                    var data = await ApiServices.PostApiCall(
                      ApiEndpoint.UPDATE_ADD,
                      JSON.stringify(body),
                      headers
                    );

                    if (!!data) {
                      if (data.status === true) {
                        toast.success(data.message);
                      } else {
                        toast.error(data.message);
                      }
                    }
                  }}
                  variant='contained'
                  className={styles.saveBtn}
                  // disabled={html === sempleHtml ? true : false}
                >
                  save
                </Button>
              </Box>
            </Box>
          </TabPanel>
          {/* close tab 5 in 1 */}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
  save_user_data: (data) => dispatch({ type: Types.LOGIN, payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Guidelines);
