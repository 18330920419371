import { Grid } from "@mui/material";
import Sidebar from "../sidebar/sidebar";
import Headar from "../headar/headar";
import { useParams } from 'react-router-dom';
import Edit_Advertisement from "../Advertisement/edit_advertisement";
const Edit_Advertisement_layout = ({props,setIsLoaded}) => {
    const { id } = useParams();
    return (
        <>
            <Grid container>
                <Grid item md={2.3}>
                    <Sidebar />
                </Grid>
                <Grid item md={9.7}>
                    <Headar />
                    
                    <Edit_Advertisement  id={id} props={props} setIsLoaded={setIsLoaded} />
                </Grid>
            </Grid>
        </>
    )
}
export default Edit_Advertisement_layout;