import { Typography, Box, createMuiTheme, ThemeProvider, createTheme } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import styles from './tostmass.module.scss';
import Stack from '@mui/material/Stack';
import React from "react";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Errmass = (mass) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(mass.mass.turelog)
    }, [mass.mass.turelog])
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const themeline = createTheme({ palette: { primary: { main: '#fff' } } })

    return (
        <>
            <ThemeProvider theme={themeline}>
                <Snackbar className={styles.Alert_conponeat} anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }} open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose}
                        severity="primary"
                        className={styles.add_new_alert}
                        sx={{ width: '100%' }}>
                        <Box className={styles.box_img_typo}>
                        <Typography >Instructions sent to <a> usermail@mail.com</a></Typography>
                        </Box>
                    </Alert>
                </Snackbar>
            </ThemeProvider>
        </>
    )
}
export default Errmass;

