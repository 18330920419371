import {
  Box,
  Button,
  Grid,
  Typography,
  createTheme,
  Menu,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import styles from "./userdetail.module.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import Pagination from '@mui/material/Pagination';
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import ApiServices from "../../config/ApiServices";
import ApiEndpoint from "../../config/ApiEndpoint";
import { useState, useEffect } from "react";
import { InputBox, Input_field, Input_lable } from "../Shareable/input";
import { toast } from "react-toastify";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getTimeDifferenceMessage } from "../../Utils/func";
import { useSearchParams } from "react-router-dom";

const User = (props) => {
  const PAGE_LIMIT = 10;
  const [open, setOpen] = React.useState(false);
  const [datalist, setDataLIST] = useState([]);
  const [open_two, setOpen_two] = React.useState(false);
  const [data_porfele, setData_porfile] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterName, setFilterName] = React.useState("");
  const [premiumUser, setPremiumUser] = React.useState("");
  const [accountType, setAccountType] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState("");
  const [totalUsers, setTotalUsers] = React.useState("");
  const [startNumber, setStartNumber] = React.useState(1);
  const [endNumber, setEndNumber] = React.useState(PAGE_LIMIT);
  const [userSearch, setUserSearch] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [apiQuery, setApiQuery] = React.useState({
    premiumUser: "",
    gender: "",
    accountType: "",
    name: "",
  });

  useEffect(() => {
    const id = searchParams.get("id");
    const role = searchParams.get("role");
    if (!!id && !!role) {
      //  handleClickOpen_two();
      USER_data(id, role);
      // setPaymentGrid(true);
      // setShowBalanceGrid(false);
      // setTopup(false);
    } else {
      // setType(CounterpartType.INDIVIDUAL)
    }
  }, [searchParams]);
  const array = [...Array(totalPages).keys()];
  const userLIST = async () => {
    var body = {
      type: "user",
      pagination: true,
      page: selectedPage,
      limit: PAGE_LIMIT,
    };

    if (!!accountType) {
      body.role = accountType;
    }
    // if (!!gender) {
    //   body.gender = gender;
    // }
    // if (!!premiumUser) {
    //   body.premmium = premiumUser;
    // }
    // if (!!filterName) {
    //   body.name = filterName;
    // }

    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.props.profile.token,
    };
    props.setIsLoaded(true);
    var data = await ApiServices.PostApiCall(
      ApiEndpoint.ADMIN_USER_LIST,
      JSON.stringify(body),
      headers
    );

    props.setIsLoaded(false);
    if (!!data) {
      if (data.status == true) {
        setDataLIST(data.data);
        setTotalPages(data.totalPage);
        setUserSearch(data.data);
        setTotalUsers(data.count);
        const Arr = [];
      } else {
        toast.error(data.message);
      }
    }
  };

  const USER_data = async (id, role) => {
    if (role == "umphires") {
      var body = {
        id_umphire: id,
      };
    } else if (role == "schools") {
      var body = {
        id_organisation: id,
      };
    } else {
      var body = {
        id_verifier: id,
      };
    }

    var headers = {
      "Content-Type": "application/json",
      "x-access-token": props.props.profile.token,
    };
    props.setIsLoaded(true);
    var data = await ApiServices.PostApiCall(
      role == "schools"
        ? ApiEndpoint.ORG_PROFILE
        : role == "verifier"
        ? ApiEndpoint.VERIFIER_PROFILE
        : ApiEndpoint.USER_PROFILE,
      JSON.stringify(body),
      headers
    );

    props.setIsLoaded(false);
    if (!!data) {
      if (data.status == true) {
        setData_porfile(data.data);
      } else {
        // toast.error(data.message)
      }
    }
  };

  // React.useEffect(() => {
  //   if (!!props.props.profile && !!props.props.profile.token) {
  //     userLIST();
  //   }
  // }, [
  //   props.props.profile,
  //   selectedPage,
  //   premiumUser,
  //   accountType,
  //   gender,
  //   filterName,
  // ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen_two = () => {
    setOpen_two(true);
  };

  const handleClose_two = () => {
    setOpen_two(false);
  };
  const themeline = createTheme({ palette: { primary: { main: "#FF7606" } } });

  return (
    <>
      <Box className={styles.box_class_in_fast_popup}>
        <Box className={styles.typo_text_hedingg}>
          <Box></Box>
          <Typography>User Profile</Typography>
          <Button onClick={handleClose_two}></Button>
        </Box>
        <Box className={styles.box_img_an_ditelas}>
          <img
            width={99}
            height={99}
            src={
              data_porfele.role == "schools" || data_porfele.role == "individual" 
                ? data_porfele?.organisationDetails?.logo_url
                : data_porfele.profile_image
            }
          />
          <Typography className={styles.typo_name_detelas}>
            {data_porfele.role == "schools" || data_porfele.role == "individual"
              ? data_porfele?.organisationDetails?.name
              :  data_porfele.first_name + " " + data_porfele.last_name}   
          </Typography>
          <Typography className={styles.not_tasker_typooo}>
            Joined {data_porfele.role == "individual" ? "Club" : data_porfele.role}{" "}
            {getTimeDifferenceMessage(new Date(data_porfele.createdAt))}
          </Typography>
        </Box>
        <Box className={styles.box_in_info_email}>
          <Typography className={styles.info_text_personn}>
            Personal Info
          </Typography>
          <Box className={styles.info_box_pohoneee}>
            <Box className={styles.box_email_last}>
              <Typography className={styles.heding_emakakaaa}>
                Email Address
              </Typography>
              <Typography className={styles.deelassss_Ssss}>
                {data_porfele.email}
              </Typography>
            </Box>
            {data_porfele.phone_number ? (
              <Box className={styles.box_email_last}>
                <Typography className={styles.heding_emakakaaa}>
                  Phone Number
                </Typography>
                <Typography className={styles.deelassss_Ssss}>
                  {data_porfele.phone_number}
                </Typography>
              </Box>
            ) : (
              ""
            )}
            {data_porfele.sport ? (
              <Box className={styles.box_email_last}>
                <Typography className={styles.heding_emakakaaa}>
                  Sport
                </Typography>
                <Typography className={styles.deelassss_Ssss}>
                  {data_porfele.sport}
                </Typography>
              </Box>
            ) : (
              ""
            )}
            {data_porfele.userSchoolAddress ? (
              <>
                <Box className={styles.box_email_last}>
                  <Typography className={styles.heding_emakakaaa}>
                    Post code
                  </Typography>
                  <Typography className={styles.deelassss_Ssss}>
                    {data_porfele?.userSchoolAddress?.post_code}
                  </Typography>
                </Box>
                <Box className={styles.box_email_last}>
                  <Typography className={styles.heding_emakakaaa}>
                    Street
                  </Typography>
                  <Typography className={styles.deelassss_Ssss}>
                    {data_porfele?.userSchoolAddress?.street_name}
                  </Typography>
                </Box>
                <Box className={styles.box_email_last}>
                  <Typography className={styles.heding_emakakaaa}>
                    Suburb
                  </Typography>
                  <Typography className={styles.deelassss_Ssss}>
                    {data_porfele?.userSchoolAddress?.suburb}
                  </Typography>
                </Box>
              </>
            ) : (
              ""
            )}

            {data_porfele.rating ? (
              <Box className={styles.box_email_last}>
                <Typography className={styles.heding_emakakaaa}>
                  Rating
                </Typography>
                <Typography className={styles.deelassss_Ssss}>
                  {data_porfele.rating}
                </Typography>
              </Box>
            ) : (
              ""
            )}
            {data_porfele.refereed_fixture ? (
              <Box className={styles.box_email_last}>
                <Typography className={styles.heding_emakakaaa}>
                  Refereed Sessions{" "}
                </Typography>
                <Typography className={styles.deelassss_Ssss}>
                  {data_porfele.refereed_fixture}
                </Typography>
              </Box>
            ) : (
              ""
            )}
           {data_porfele?.role == "individual" && data_porfele?.first_name ? (
              <Box className={styles.box_email_last}>
                <Typography className={styles.heding_emakakaaa}>
                  Name
                </Typography>
                <Typography className={styles.deelassss_Ssss}>
                  {data_porfele?.first_name}
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
        {data_porfele?.bio?.length > 0 ? (
          <Box style={{ marginTop: "10px" }}>
            <Typography>Bio</Typography>

            <Typography style={{ fontSize: "12px" }}>
              {data_porfele?.bio}
            </Typography>
          </Box>
        ) : (
          ""
        )}

        {data_porfele?.recommendedList?.length > 0 ? (
          <Box className={styles.box_text_tasks_imggg}>
            <Typography>RECOMMENDED BY</Typography>
            <Box>
              {data_porfele?.recommendedList?.length > 0
                ? data_porfele.recommendedList.map((item, index) => {

                    return (
                      <>
                        <Box style={{ display: "flex", gap: "15px" }}>
                          <Box className={styles.tag_box}>
                            <div className='w-10 self-stretch py-0.5 justify-center items-center gap-2.5 flex'>
                              <img
                                className='w-10 h-10 relative rounded-[128px]'
                                src={item.logo_url}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </Box>

                          <div className='grow shrink basis-0 h-14 py-2 justify-start items-center gap-3 flex'>
                            <div className='grow shrink basis-0 flex-col justify-start items-start inline-flex'>
                              <div className="self-stretch h-[22px] text-neutral-950 text-[17px] font-normal font-['Roboto'] leading-snug">
                                {item.name}
                              </div>
                              <div className="self-stretch text-zinc-700 text-[13px] font-normal font-['Roboto'] leading-[18px]">
                                {item.school_type}
                              </div>
                            </div>
                            <div className='w-10 self-stretch' />
                          </div>
                        </Box>
                      </>
                    );
                  })
                : ""}
            </Box>
          </Box>
        ) : (
          ""
        )}
        {data_porfele?.documentList?.length > 0 ? (
          <Box className={styles.box_main_aupoad_fileee}>
            <Typography>Certifications</Typography>
            <Box className={styles.img_inuplode_filee_img}>
              {/* 
                  <a href={row.document_url} target="_blank"> {row.type}</a>
                ))} */}

              {!!data_porfele.documentList &&
                data_porfele.documentList.length !== 0 &&
                data_porfele.documentList.map((row) => (
                  <List>
                    <ListItem
                      secondaryAction={
                        <IconButton edge='end' aria-label='delete'>
                          <Checkbox
                            onClick={async (e) => {
                              var body = {
                                id: row?.id,
                                status: e.target.checked ? "active" : "pending",
                              };

                              var headers = {
                                "Content-Type": "application/json",
                                "x-access-token": props.props.profile.token,
                              };
                              props.setIsLoaded(true);
                              await ApiServices.PostApiCall(
                                ApiEndpoint.UPDATE_DOC,
                                JSON.stringify(body),
                                headers
                              );
                              props.setIsLoaded(false);
                            }}
                            defaultChecked={row?.status === "active"}
                          />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={
                          <a href={row.document_url} target='_blank'>
                            {" "}
                            {row.type}
                          </a>
                        }
                      />
                    </ListItem>
                  </List>
                ))}
            </Box>
          </Box>
        ) : (
          ""
        )}
        {data_porfele?.sportList?.length > 0 ? (
          <Box className={styles.box_main_video_this}>
            <Typography>Sports</Typography>
            <Box
              style={{ display: "flex", gap: "10px" }}
              className={styles.video_tage_box}
            >
              {!!data_porfele.sportList &&
                data_porfele.sportList.length !== 0 &&
                data_porfele.sportList.map((row) => (
                  <>
                    <Typography>{row.name}, </Typography>
                  </>
                ))}
            </Box>
          </Box>
        ) : (
          ""
        )}

        {data_porfele?.userSchoolSport?.length > 0 ? (
          <Box className={styles.box_main_video_this}>
            <Typography>Sports</Typography>
            <Box
              style={{ display: "flex", gap: "10px" }}
              className={styles.video_tage_box}
            >
              {!!data_porfele.userSchoolSport &&
                data_porfele.userSchoolSport.length !== 0 &&
                data_porfele.userSchoolSport.map((row) => (
                  <>
                    <Typography>{row.name}, </Typography>
                  </>
                ))}
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Box>
      {/* </Dialog> */}

      <Dialog
        open={anchorEl}
        maxWidth='sm'
        fullWidth
        onClose={() => {
          setAnchorEl(false);
        }}
      >
        <Box className={styles.main_box_dialog}>
          <Box className={styles.insideBox}>
            <Grid
              container
              sx={{
                "& .MuiGrid-item": {
                  padding: "5px",
                },
              }}
            >
              <Grid item md={12}>
                <InputBox>
                  <Input_lable>Account Type</Input_lable>

                  <RadioGroup
                    value={apiQuery.accountType}
                    onChange={(event) => {
                      const apiQueryObj = { ...apiQuery };

                      apiQueryObj.accountType = event.target.value;
                      setApiQuery(apiQueryObj);
                    }}
                    aria-labelledby='demo-radio-buttons-group-label'
                    defaultValue='female'
                    name='radio-buttons-group'
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      "& .MuiFormControlLabel-root": {
                        padding: "0px",
                      },
                    }}
                  >
                    <FormControlLabel
                      value='umphires'
                      control={<Radio />}
                      label='Umphire'
                    />
                    <FormControlLabel
                      value='verifier'
                      control={<Radio />}
                      label='Verifier'
                    />
                    <FormControlLabel
                      value='schools'
                      control={<Radio />}
                      label='Organisations'
                    />
                  </RadioGroup>
                </InputBox>
              </Grid>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  columnGap: "10px",
                }}
              >
                <Button
                  className={styles.cancle_button}
                  onClick={() => {
                    setAnchorEl(false);
                    const defaultobj = {
                      premiumUser: "",
                      gender: "",
                      accountType: "",
                    };

                    setApiQuery(defaultobj);
                    setAccountType("");
                    setPremiumUser("");
                    setGender("");
                    setFilterName("");
                  }}
                >
                  Clear
                </Button>
                <Button
                  className={styles.cancle_button}
                  onClick={() => {
                    setAnchorEl(false);
                    setAccountType(apiQuery.accountType);
                    setPremiumUser(apiQuery.premiumUser);
                    setFilterName(apiQuery.name);
                    setGender(apiQuery.gender);
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
export default User;
