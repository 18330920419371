import EventEmitter from 'events'
// const Dotenv = require('dotenv-webpack');

export default {
    // BASE_API_URL: "http://192.168.10.112:8081/api/v1/",
    //BASE_API_URL: "https://umphire-backend-uat.ap-southeast-2.elasticbeanstalk.com/api/v3/",
     BASE_API_URL: "https://api-umphire.umphire.au/api/v3/",
    // BASE_API_URL: process.env.REACT_APP_API_URL,
    IS_INTERNET_AVAILABLE: true,
    SESSION_TOKEN: 'session_token',
    ACCESS_TOKEN: 'access_token',
    USER_DATA: 'user_data',
    CUST_LOADER: '',
    EventEmitter: new EventEmitter()
}
