import moment from "moment";
import React from "react";

export const errorGenerator = (field) => {
  return `${field} is required!`;
};

export function replaceFirstTwoLetters(inputString, replacement) {
  if (inputString.length >= 2) {
    return replacement + inputString.substring(2);
  } else {
    return inputString; // Handle cases where the string is shorter than 2 characters
  }
}

export function getTimeDifferenceMessage(targetDate) {
  const currentDate = new Date();
  const timeDifference = currentDate - targetDate;
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
  const daysDifference = Math.floor(hoursDifference / 24);
  const monthsDifference = Math.floor(daysDifference / 30);
  const yearsDifference = Math.floor(monthsDifference / 12);

  if (hoursDifference < 24) {
    if (hoursDifference < 1) {
      return "Less than 1 hour ago";
    }
    return `${hoursDifference} hours ago`;
  } else if (daysDifference < 30) {
    return `${daysDifference} days ago`;
  } else if (monthsDifference < 12) {
    return `${monthsDifference} months ago`;
  } else {
    return `${yearsDifference} years ago`;
  }
}

export function removeDuplicates(array) {
  return array.filter((value, index, self) => {
    // Return true if the value's index is the first occurrence of that value in the array
    return self.indexOf(value) === index;
  });
}

export function cutAndAppendEllipsis(inputString, maxLength = 20) {
  if (inputString.length > maxLength) {
    return inputString.slice(0, maxLength - 3) + "...";
  } else {
    return inputString;
  }
}

export function formatDateTime(dateTime) {
  const jsDate = moment("1900-01-01").add(dateTime, "days").toDate();
  return moment(jsDate).format("DD:MM:YYYY hh:mm a");
}

// Format date and time values in the array

