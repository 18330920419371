import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Lonin from "./componehts/login/login";
import Forgot_Password from "./componehts/login/Forgot_Password";
import Reset_Password from "./componehts/login/ResetPassword_New";
import Change_Password from "./componehts/login/Change_Password";
import Dashboard_layout from "./componehts/pages/Dashboard_layout";
import User_layout from "./componehts/pages/User_layout";
import Session_layout from "./componehts/pages/Session_layout";
import Sub_Admins_layout from "./componehts/pages/Sub_Admins_layout";
import Support_layout from "./componehts/pages/Support_layout";
import Guidelines from "./componehts/pages/Guidelines_layout";
import { Types } from "../src/constants/actionTypes";
import { connect } from "react-redux";
import { CssBaseline, CircularProgress, Box } from "@mui/material";
// import "rsuite/dist/rsuite.min.css";
import { useEffect, useState } from "react";
import PushNotification from "./componehts/pages/Push-notification";
import Report_layout from "./componehts/pages/report_layout";
import Usersdetails_layout from "./componehts/pages/Userdetails_layout";
import Job_layout from "./componehts/pages/Job_layout";
import Verification from "./componehts/login/verification";
import Ads_layout from "./componehts/pages/Advertisement_layout";
import Advertisement_layout from "./componehts/pages/Advertisement_layout";
import Add_Advertisement_layout from "./componehts/pages/Add_Advertisement_layout";
import Edit_Advertisement_layout from "./componehts/pages/Edit_Advertisement_layout";

function App(props) {
  const [isProgress, setIsProgress] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { Component, pageProps } = props;

  return (
    <>
      {isProgress && (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            top: 230,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <CircularProgress color="success" />
        </Box>
      )}

      <Router>
        <Routes>
          <Route path="/" element={<Lonin />} />
          <Route path="forgotpassword" element={<Forgot_Password />} />
          <Route path="/verification/resetpassword" element={<Reset_Password  />} />
          <Route path="changepassword" element={<Change_Password />} />
          <Route
            path="dashboard"
            element={
              <Dashboard_layout props={props} setIsLoaded={setIsLoaded} />
            }
          />
          <Route
            path="user"
            element={<User_layout props={props} setIsLoaded={setIsLoaded} />}
          />
          <Route
            path="session"
            element={<Session_layout props={props} setIsLoaded={setIsLoaded} />}
          />
          <Route
            path="advertisement"
            element={<Advertisement_layout props={props} setIsLoaded={setIsLoaded} />}
          />
           <Route
            path="add-advertisement"
            element={<Add_Advertisement_layout props={props} setIsLoaded={setIsLoaded} />}
          />
          <Route
            path="edit-advertisement/:id"
            element={<Edit_Advertisement_layout props={props} setIsLoaded={setIsLoaded} />}
          />
          <Route
            path="job"
            element={<Job_layout props={props} setIsLoaded={setIsLoaded} />}
          />
          <Route
            path="reportfixture"
            element={
              <Sub_Admins_layout props={props} setIsLoaded={setIsLoaded} />
            }
          />
          <Route
            path="report"
            element={
              <Report_layout props={props} setIsLoaded={setIsLoaded} />
            }
          />
          <Route
            path="userdetails"
            element={
              <Usersdetails_layout props={props} setIsLoaded={setIsLoaded} />
            }
          />
          <Route
            path="support"
            element={<Support_layout props={props} setIsLoaded={setIsLoaded} />}
          />
          <Route
            path="guidelines"
            element={<Guidelines props={props} setIsLoaded={setIsLoaded} />}
          />
          <Route
            path="PushNotification"
            element={
              <PushNotification props={props} setIsLoaded={setIsLoaded} />
            }
          />
           <Route
            path="verification"
            element={
              <Verification props={props} setIsLoaded={setIsLoaded} />
            }
          />

        </Routes>
      </Router>
    </>
  );
}
const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
  save_user_data: (data) => dispatch({ type: Types.LOGIN, payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
// export default ;
