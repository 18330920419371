import { Grid } from "@mui/material";
import React from "react";
import Sidebar from "../sidebar/sidebar";
import Headar from "../headar/headar";

const Layout = ({ children }) => {
  return (
    <>
      <Grid container>
        <Grid item md={3}>
          <Sidebar />
        </Grid>
        <Grid item md={9}>
          <Headar />
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default Layout;
